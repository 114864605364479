import { useState } from "react";
import { Link } from "react-scroll";
import { FaHandHoldingUsd, FaChevronDown } from "react-icons/fa";
import { MdArticle, MdSyncProblem } from "react-icons/md";
import {
  Container,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import LinkNavbar from "./LinkNavbar";

const menuItems = [
  {
    id: 1,
    name: "Intro",
    icon: "fa fa-home",
    sectionId: "intro",
    offset: -130,
  },
  {
    id: 2,
    name: "Problématique",
    icon: "fa fa-sync-alt",
    sectionId: "problematique",
    offset: -230,
  },
  {
    id: 3,
    name: "Nos services",
    icon: "ni ni-settings-gear-65",
    sectionId: "services",
    offset: 0,
  },
  {
    id: 4,
    name: "Offres et clients",
    icon: "fa fa-hand-holding-usd",
    sectionId: "offre",
    offset: -130,
  },
  {
    id: 5,
    name: "Blog",
    icon: "fa fa-newspaper",
    href: "/blog",
  },
  {
    id: 6,
    name: "En savoir plus",
    icon: "fa fa-info-circle",
    sectionId: "contact",
    offset: -130,
  },
];

const Rubrique = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {/* <Nav className="nav-grand" navbar>
        <div onClick={toggle}>
          Rubriques
          <FaChevronDown />
        </div>
        {isOpen && (
          <>
            {menuItems.map((item) => (
              <NavItem
                key={item.id}
                style={{
                  color: "white",
                }}
              >
                {item.sectionId ? (
                  <Link
                    to={item.sectionId}
                    spy={true}
                    smooth={true}
                    offset={item.offset}
                    duration={500}
                  >
                    <i className={item.icon}></i>
                    <span className="nav-link-inner--text">{item.name}</span>
                  </Link>
                ) : (
                  <a href={item.href}>
                    <i className={item.icon}></i>
                    <span className="nav-link-inner--text">{item.name}</span>
                  </a>
                )}
              </NavItem>
            ))}
          </>
        )}
      </Nav> */}
      <LinkNavbar />
    </>
  );
};

export default Rubrique;
