export const htToTtc = (ht, tva, toFixed = 2) => {
  if (!tva || tva === 0) return ht;
  return Number.parseFloat((ht * parseInt(tva)) / 100 + ht).toFixed(toFixed);
};

export const htToTvAmount = (ht, tvaPercentage) => {
  return Number.parseFloat((!tvaPercentage || tvaPercentage === 0) ? 0: (ht * parseInt(tvaPercentage)) / 100);
};

export const floatSum = (floatArray, toFixed = 2) => {
  const res = floatArray.reduce(
    (a, b) => Number.parseFloat(a) + Number.parseFloat(b),
    0
  );
  return toFixed ? Number.parseFloat(res.toFixed(toFixed)) : res;
};

export const numberWithCommas = (x) => {
  return x
    ? x
      .toString()
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    : x;
};
