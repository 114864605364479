import React, { useState } from "react";
import { Comment } from "./Comment";
import { Button, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faComment } from "@fortawesome/free-regular-svg-icons";

import {
  faThumbsUp as faSolidThumbsUp,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { socialService } from "utils/_services/social.services";

const PostActions = ({ postPub }) => {
  let addCommentInput;
  const [isOpen, setIsOpen] = useState(false);

  const [comments, setComments] = useState(postPub.comments);
  const [comment, setComment] = useState("");

  const toggle = () => setIsOpen(!isOpen);

  const commentClick = () => {
    setIsOpen(true);
    addCommentInput.focus();
  };

  function sendComment(e) {
    e.preventDefault();
    if (comment === "") {
      NotificationManager.error("Le commentaire ne doit pas être vide.");
      return;
    }

    const data = {
      content: comment,
      postId: postPub.id.toString(),
    };

    socialService
      .addComment(data)
      .then((response) => {
        if (response !== undefined) {
          const searchCommentsdata = {
            postId: postPub.id.toString(),
          };

          socialService
            .searchComments(searchCommentsdata)
            .then((searchResponse) => {
              setComments(searchResponse.data);
            });
          NotificationManager.success("Commentaire ajouté avec succes");
          setComment("");
        } else {
          NotificationManager.error("Problème lors de l'ajout du commentaire");
        }
      })
      .catch((err) =>
        NotificationManager.error("Problème lors de l'ajout du commentaire")
      );
  }

  return (
    <>
      <div className="mb-2 social-actions">
        {/* <button class="btn btn-primary">
          <i class="fa fa-thumbs-up mr-2">Like</i>{" "}
        </button> */}
        <Button
          color="link"
          onClick={commentClick}
          className="text-muted btn-hover-gray btn social-btn"
        >
          <FontAwesomeIcon icon={faThumbsUp} color="" className="mr-2" />
          J'aime
        </Button>
        <Button
          onClick={commentClick}
          color="link"
          className="text-muted btn-hover-gray social-btn"
        >
          <FontAwesomeIcon icon={faComment} className="mr-2" />
          Commenter
        </Button>
        <Button
          onClick={toggle}
          color="link"
          className="text-muted text-decoration-underline btn-hover-gray social-btn"
        >
          <u> {comments ? comments.length : 0} Commentaire(s)</u>
        </Button>
      </div>

      <Collapse className="" isOpen={isOpen}>
        <form onSubmit={sendComment}>
          <div className="input-group mb-2">
            <input
              placeholder="Ajouter un commentaire..."
              type="text"
              className="form-control"
              value={comment}
              ref={(ip) => (addCommentInput = ip)}
              onInput={(e) => setComment(e.target.value)}
              name="messageContent"
            />
            <div class="input-group-append">
              {/*  */}
              <button
                className="btn-right-icon"
                style={{ cursor: comment === "" ? "not-allowed" : "pointer" }}
                disabled={comment === ""}
                type="submit"
              >
                <FontAwesomeIcon
                  color={comment === "" ? "" : "blue"}
                  icon={faPaperPlane}
                  className="form-control-icon"
                />
              </button>
            </div>
          </div>
        </form>
        {comments ? (
          comments.map((comment, index) => <Comment postComment={comment} />)
        ) : (
          <></>
        )}
      </Collapse>
      <NotificationContainer />
    </>
  );
};

export { PostActions };
