import React from 'react'
import { Media } from 'reactstrap';

export default function UserAvatarInformation(props) {
    const { photoUrl, firstName, lastName, jobTitle, companyName } = props;

    return (
        <Media>
            <Media
                left
                className="align-self-center mr-3 avatar avatar-md"
            >
                <img
                    alt="profil"
                    className="avatar-image"
                    src={
                        photoUrl ??
                        "https://images.assetsdelivery.com/compings_v2/triken/triken1608/triken160800029.jpg"
                    }
                />
            </Media>
            <Media body>
                <div className="mt-0 d-flex text-inverse text-capitalize">
                    <strong>
                        {firstName}{" "}
                        {lastName}
                    </strong>
                </div>
                <span>
                    {jobTitle},{" "}
                    {companyName}
                </span>
            </Media>
        </Media>
    )
}
