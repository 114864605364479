import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import prospection from "../../../../assets/img/welcome/prospection.png";
import valorisation from "../../../../assets/img/welcome/valorisation.png";
import digitalisation from "../../../../assets/img/welcome/digitalisation.png";
import "./problematique.css";
import Cards from "components/Ui/Cards";
import "../../../Ui/fond.css";

const data = [
  {
    image: prospection,
    title: "Prospection",
    text: `La prospection c’est coûteux, chronophage et énergivore, passe la solution BTPGO.`,
    // lien: "En savoir plus ...",
  },
  {
    image: valorisation,
    title: "Valorisation",
    text: `Brillez sur votre site bâtiment pour captiver l'attention des clients potentiels et démarquez-vous dans un marché concurrentiel.`,
    // lien: "En savoir plus ...",
  },
  {
    image: digitalisation,
    title: "Digitalisation",
    text: `Débloque le maximum de ton potentiel digital.`,
    // lien: "En savoir plus ...",
  },
];
const Problématique = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  return (
    <div
      className="container-fluid bgDefault "
      id="problematique"
      data-aos="fade-up"
    >
      <h2 className="title">
        Construisez votre réseau grâce à ces outils simples et adaptés
      </h2>
      <Cards data={data} />
    </div>
  );
};

export default Problématique;
