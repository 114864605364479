import React from "react";
import { NavLink } from "react-router-dom";

const CustomNavLink = (props) => {
  const { title, action, to } = props;
  return (
    <NavLink to={to} onClick={action}>
      {title}
    </NavLink>
  );
};

export default CustomNavLink;
