import React from "react";
import Buttons from "components/Ui/Button";
import "../../../Ui/fond.css"

const DescriptionArtisans = () => {
  return (
    <div
      className="container-fluid bgDefault "
      id="services"
    >
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div className="col-md-6 d-flex justify-content-center ">
          <img
            className="imgContactProperty"
            alt="..."
            src={require("../../../../assets/img/principal/marketingArtisan.png")}
          />
        </div>
        <div className="col-md-6" style={{ padding: "66px 60px" }}>
          <p style={{ color: "black" }}>
            Démarquez-vous en tant que professionnel BTP avec notre expertise en
            marketing en ligne. Nous comprenons les défis auxquels vous êtes
            confrontés en matière de visibilité et de valorisation de vos
            projets.
          </p>
          <p style={{ color: "black" }}>
            C'est pourquoi nous offrons des solutions personnalisées pour
            renforcer votre image de marque en ligne. Avec notre aide, vous
            pouvez attirer de nouveaux clients et faire croître votre
            entreprise. Faites-nous confiance pour élaborer une stratégie de
            marketing en ligne efficace qui répondra à vos besoins spécifiques.
          </p>
          <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
        </div>
      </div>
    </div>
  );
};

export default DescriptionArtisans;
