import React, { useState } from "react";
import Buttons from "components/Ui/Button";
import { Button, FormGroup, Form } from "reactstrap";
import SearchCity from "../../components/Search/SearchCity";
import SearchJob from "../../components/Search/SearchJob";

import "./recherche.css";
import UsersSearch from "components/Search/UsersSearch";
import { userService } from "utils/_services/user.service";

const Search = () => {
  const [user, setUser] = useState([]);
  const [userIndex, setUserIndex] = useState([]);
  const [searchTotalResult, setSearchTotalResult] = useState(0);

  const [selectedJobValue, setSelectedJobValue] = useState(0);
  const [selectedCityValue, setSelectedCityValue] = useState(0);

  const changeSelectdCityValue = (city) => {
    if (city != null) {
      const { id } = city;
      setSelectedCityValue(id);
    }
  };

  const changeSelectdJobValue = (job) => {
    if (job != null) {
      const { value } = job;
      setSelectedJobValue(value);
    }
  };

  const onChangeResultClick = async (resultNumber) => {
    const data = {
      jobId: selectedJobValue,
      cityId: selectedCityValue,
      resultNumber:
        userIndex === 0 && resultNumber === 1 ? 2 : userIndex + resultNumber,
    };

    userService.searchUser(data).then((res) => {
      if (res !== undefined && res.data.userResult != null) {
        setUser(res.data.userResult);
        console.log(" user result count => ", res.data.totalResult);

        setSearchTotalResult(res.data.totalResult);
        setUserIndex(res.data.resultIndex);
      } else {
        setSearchTotalResult(0);
        setUserIndex(0);
      }
    });
  };

  const onPreviousResultClick = async () => {
    onChangeResultClick(-1);
  };

  const onNextResultClick = async () => {
    onChangeResultClick(1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      jobId: selectedJobValue,
      cityId: selectedCityValue,
    };

    userService.searchUser(data).then((res) => {
      if (res !== undefined && res.data.userResult != null) {
        setUser(res.data.userResult);
        console.log(" user result count => ", res.data.totalResult);

        setSearchTotalResult(res.data.totalResult);
        setUserIndex(res.data.resultIndex);
      } else {
        setSearchTotalResult(0);
        setUserIndex(0);
      }
    });
  };

  return (
    <>
      <div
        className="header pt-2 pt-md-5 opacity-8"
        style={{         
          background: "linear-gradient(90deg,  rgba(179, 216, 232, 1) 0%,  rgba(39, 166, 221, 1) 100%)",
        }}
      >
        <Form onSubmit={onSubmit}>
          <div className="headerForm">
            <div className=" col-lg-3 ">
              <FormGroup>
                <label
                  className="form-control-label text-white"
                  htmlFor="input-address"
                >
                  Profession
                </label>
                <SearchJob
                  changeSelectdJobValue={changeSelectdJobValue}
                  // selectedJobValue={changeSelectdJobValue}
                />
              </FormGroup>
            </div>
            <div className=" col-lg-3 ">
              <label
                className="form-control-label text-white"
                htmlFor="input-address"
              >
                Ville
              </label>
              <SearchCity
                changeSelectdCityValue={changeSelectdCityValue}
                titleField="Ville d'activité"
              />
            </div>
            <div className="col-lg-2">
              <div className="headerBtn">
                <button
                  className="btn "
                  type="submit"
                  style={{
                    background: "linear-gradient(90deg, rgba(111,174,222,1) 0%, rgba(16,122,202,1) 100%)",
                    color: "white",
                    borderColor: "#f7fafc",
                  }}
                >
                  <i className="fa fa-search" />
                  Chercher
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {user?.length === 0 || searchTotalResult === 0? (
        <div className="content">
          <div className="d-flex justify-content-center ">
            <img
              className="search"
              src={require("../../assets/img/admin/rechercher_01.png")}
              alt=".."
            />
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between m-3">
            <div className="d-flex justify-content-start">
              { searchTotalResult > 1 && userIndex > 1 && (
                <Button
                  className="btn-icon btn-3"
                  color="primary"
                  type="button"
                  onClick={onPreviousResultClick}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-left" />
                  </span>
                  <span className="btn-inner--text">
                    Professionel précédent
                  </span>
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-end">
              {
                searchTotalResult > 1 &&
                userIndex < searchTotalResult && (
                  <Button
                    className="btn-icon btn-3"
                    color="primary"
                    type="button"
                    onClick={onNextResultClick}
                  >
                    <span className="btn-inner--text">
                      Professionel suivant
                    </span>
                    <span className="btn-inner--icon">
                      <i className="ni ni-bold-right" />
                    </span>
                  </Button>
                )}
            </div>
          </div>
          <UsersSearch user={user} />
        </>
      )}
    </>
  );
};

export default Search;
