import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jobService } from "utils/_services/job.services";

const initialState = {
  jobs: [],
  status: "idle",
  error: null,
};

export const fetchJobs = createAsyncThunk("jobs/getAll", async () => {
  const response = await jobService.getAllJobs();
  return response.data.map((entry) => ({
    label: entry.title,
    value: entry.id,
  }));
});

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.jobs = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllJobs = (state) => state.jobs.jobs;
export const getJobsStatus = (state) => state.jobs.status;
export const getJobsError = (state) => state.jobs.error;

export default jobsSlice.reducer;
