import Cards from "components/Ui/Cards";
import Partenaires from "components/Ui/Partenaires";
import React from "react";
import search from "../../../../assets/img/principal/searchProperti.png";
import profil from "../../../../assets/img/principal/profilProperti.png";
import tools from "../../../../assets/img/principal/toolsProperti.png";
import PhotoCard from "components/Ui/PhotoCard";
import "../../../Ui/fond.css";

const data = [
  {
    image: profil,
    title: "A-profil",
    text: `Un réseau social qui vous permettra d'obtenir plus de visibilité ou de suivre vos artisans préférés.`,
    lien: "En savoir plus ...",
  },
  {
    image: search,
    title: "A-search",
    text: ` Vous met en relation avec des professionnels qui ont été évalués et notés par d'autres utilisateurs.`,
    lien: "En savoir plus ...",
  },
  {
    image: tools,
    title: "A-tools",
    text: `Notre outil de G.M.A.O vous permet de gérer votre établissement de manière sereine, efficace et optimisée tout au long du processus.`,
    lien: "En savoir plus ...",
  },
];

const SolutionProperty = () => {
  return (
    <div className="container bgDefault">
      <div className="col-md-12 mt-5 mb-4">
        <h2
          className="display-4 pb-4 d-flex justify-content-center"
          style={{ fontWeight: "bold", gap: "1%", color: "rgb(50,104,136)" }}
        >
          Nos solutions
        </h2>
      </div>
      <PhotoCard photoData={data} />
      <div className="container">
        <div
          style={{
            alignItems: "center",
            height: "auto",
            maxWidth: "1275px",
            padding: "66px 45px",
          }}
        >
          <Partenaires />
        </div>
      </div>
    </div>
  );
};

export default SolutionProperty;
