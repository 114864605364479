import Footer from "components/Footers/Footer";
import ContactArtisans from "components/Welcome/Artisans/contact/ContactArtisans";
import DescriptionArtisans from "components/Welcome/Artisans/description/DescriptionArtisans";
import IntroArtisans from "components/Welcome/Artisans/intro/IntroArtisans";
import Portfolio from "components/Welcome/Artisans/portfolio/Portfolio";
import ProblematiqueArtisans from "components/Welcome/Artisans/problematique/ProblematiqueArtisans";
import SolutionsArtisans from "components/Welcome/Artisans/solutions/SolutionsArtisans";
import React from "react";

const WelcomeArtisans = () => {
  return (
    <>
      <IntroArtisans />
      <Portfolio />
      <ProblematiqueArtisans />
      <SolutionsArtisans />
      <ContactArtisans />
      <DescriptionArtisans />
      <Footer />
    </>
  );
};

export default WelcomeArtisans;
