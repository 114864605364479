import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AuthNavbar from "components/Navbars/Auth/AuthNavbar.js";
import routes from "routes.js";
import Footer from "components/Footers/Footer";
import { loadStripe } from "@stripe/stripe-js";
import Completion from "components/Payment/Completion";
import { paymentService } from "utils/_services/payment.service";
import { NotificationContainer } from "react-notifications";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();

  React.useEffect(() => {
    paymentService.paymentConfig().then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });

    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div
        className="main-content "
        style={{ background: "white" }}
        ref={mainContent}
      >
        <AuthNavbar />
        <div className="  py-7 py-lg-7">
          <Container>
            <div className="header-body text-center mb-7">
              {/* <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                  <p className="text-lead text-light">
                    Use these awesome forms to login or create new account in
                    your project for free.
                  </p>
                </Col>
              </Row> */}
            </div>
          </Container>
          {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        </div>
        {/* Page content */}
        <Container>
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              {/* <Redirect from="*" to="/auth/login" /> */}
              <Route
                path="/completion"
                element={<Completion stripePromise={stripePromise} />}
              />
            </Switch>
          </Row>
        </Container>
        <Footer />
      </div>
      <NotificationContainer />
    </>
  );
};

export default Auth;
