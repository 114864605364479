import React from "react";
import Buttons from "components/Ui/Button";
import "../../../Ui/fond.css";

const Description = () => {
  return (
    <div className="container-fluid bgDefault" id="services">
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div className="col-md-6" style={{ padding: "66px 60px" }}>
          <h2
            className="display-4 pb-4 d-flex mt-3 "
            style={{ gap: "2%", color: "rgb(50,104,136)" }}
          >
            A propos de BtpGo
          </h2>
          <p style={{ color: "black" }}>
            Trouver des professionnels qualifiés pour vos projets de rénovation
            est souvent énergivore et chronophage, tout comme gérer efficacement
            ces projets.
          </p>
          <p style={{ color: "black" }}>
            BtpGo vous propose une sélection d'artisans triés sur le volet et
            expérimentés pour garantir la qualité de leurs travaux. Vous pouvez
            facilement les sélectionner en fonction de leurs compétences, de
            leur expérience et de leurs tarifs.
          </p>
          <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
        </div>
        <div className="col-md-6 d-flex justify-content-center ">
          <img
            style={{ maxWidth: "90%" }}
            // className="imgContactProperty"
            alt="..."
            src={require("../../../../assets/img/principal/marketingProperti.png")}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <p
          style={{
            fontWeight: "bold",
            color: "rgb(50,104,136)",
            marginBottom: "5rem",
          }}
        >
          Nous offrons également des outils digitaux pour suivre l'avancement de
          vos projets de rénovation en temps réel.
        </p>
      </div>
    </div>
  );
};

export default Description;
