import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const HeaderGmao = () => {
  return (
    <>
      <div
        className="header pb-3 pt-5 pt-md-6 opacity-8"
        style={{
          background: "rgb(179, 216, 232)",
          background:
            "linear-gradient(90deg,  rgba(179, 216, 232, 1) 0%,  rgba(39, 166, 221, 1) 100%)",
        }}
      >
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Link
                  to="/admin/demande-travaux"
                  tag={Link}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h3"
                            className=" font-weight-bold mb-0"
                          >
                            Demandes de travaux
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div
                            className="icon icon-shape text-white rounded-circle shadow"
                            style={{ background: "#9077ed" }}
                          >
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link
                  to="/admin/patrimoine"
                  tag={Link}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h3"
                            className=" font-weight-bold mb-0"
                          >
                            Gérer mon patrimoine
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i
                              className="ni ni-building"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link
                  to="/admin/ged"
                  tag={Link}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h3"
                            className=" font-weight-bold mb-0"
                          >
                            Outils
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="ni ni-settings" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link
                  to="/admin/ged"
                  tag={Link}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h3"
                            className=" font-weight-bold mb-0"
                          >
                            Aide
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeaderGmao;
