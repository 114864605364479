import Axios from "./caller.service";

const getReviewssByUserId = (companyId) => {
  return Axios.get(`/api/Review/all/${companyId}`);
};

const AddReview = (data) => {
  return Axios.post(`/api/Review/add`, data);
};

export const reviewsService = {
  getReviewssByUserId,
  AddReview
};
