import Axios from "./caller.service";

const getAllSkills = () => {
  return Axios.get("/api/Skills/all");
};

const getSkillsByUserId = (id) => {
  return Axios.get(`/api/Skills/${id}`);
};

const getSkillsByJobId = (jobId) => {
  return Axios.get(`/api/Skills/job/${jobId}`);
};

const updateSkills = (skills) => {
  return Axios.put("/api/UserSkills", skills);
};

export const skillsService = {
  getAllSkills,
  getSkillsByUserId,
  updateSkills,
  getSkillsByJobId,
};
