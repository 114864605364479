import React, { useEffect, useState } from 'react';
import ReviewList from 'components/Review/ReviewList';
import { reviewsService } from 'utils/_services/review.service';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { capitalizeFirstLowercaseRest } from 'utils/_helpers/stringUtils';
import Clipboard from 'components/Forms/others/clipboard';
import GenerateFormInput from 'components/Ui/GenerateFormInput';
import { getBaseURL } from 'utils/_helpers/urlUtils';
import CompanyReviews from './CompanyReviews';


function ReviewsPage(props) {
  const { companyId, companyName } = props;

  const [linkToAddReview, setLinkToAddReview] = useState();

  useEffect(() => {
    const currentURL = window.location.href;
    const baseURL = getBaseURL(currentURL);
    setLinkToAddReview(`${baseURL}/admin/addReview/${companyId}`);
  }, [companyId]);

  return (
    <div className="reviews-page">
      <Card className="bg-secondary shadow mb-2">
        <CardHeader>
          <h2>Avis de l'entreprise {capitalizeFirstLowercaseRest(companyName)}</h2>
        </CardHeader>
        <CardBody>
          {linkToAddReview &&
            <GenerateFormInput
              forInput="input-siret"
              label="Demandez à vos clients de donner leur avis via ce lien:"
              editModeClassName={true}
              value={linkToAddReview}
              disabled={true}
              type="text" ocal
              required={true}
              iconNextInput={<Clipboard value={linkToAddReview} style={{ fontSize: "20px" }}>
              </Clipboard>}
            />
          }
          <CompanyReviews companyId={companyId} /></CardBody>
      </Card>
    </div>
  );
}

export default ReviewsPage;
