import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    // borderWidth: 1,
    // borderColor: "#bff0fd",
  },
});

const Table = ({ form }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader
        headers={form.headers}
        customCss={form.css?.header ?? { view: {}, text: {} }}
      />
      <TableRow
        form={form}
        customCss={form.css?.row ?? { view: {}, text: {} }}
      />
    </View>
  );
};

export default Table;
