import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/slices/userSlice";
import workAvalabilityReducer from "../features/slices/workAvailabilitySlice";
import jobsReducer from "../features/slices/jobsSlice";
import customerWebsiteReducer from "../features/slices/customerWebsiteSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    workAvailabilities: workAvalabilityReducer,
    jobs: jobsReducer,
    customerWebsite: customerWebsiteReducer,
  },
});
