export const cgvData = [
  {
    titre: "Clause n° 1 : Objet",
    text: ` Les conditions générales de vente décrites ci-après détaillent les droits et
    obligations de la société Destock BTP SAS et de sa filiale BtpGO dans le cadre de
    la vente des marchandises suivantes : service d’abonnement mensuel pour la jouissance 
    des services proposés par le site BtpGO.Toute prestation accomplie par la société 
    implique donc l'adhésion sans réserve de l'acheteur aux présentes conditions générales
    de vente.`,
  },
  {
    titre: "Clause n° 2 : Prix",
    text: `Les prix des prestations vendues sont ceux en vigueur au jour de la prise de commande. 
    Ils sont libellés en euros et calculés hors taxes. Par voie de conséquence, ils seront majorés 
    du taux de TVA et des frais de transport applicables au jour de la commande.La société Destock 
    BTP SAS s'accorde le droit de modifier ses tarifs à tout moment. Toutefois, elle s'engage à 
    facturer les prestations commandées aux prix indiqués lors de l'enregistrement de la commande.`,
  },
  {
    titre: "Clause n° 3 : Rabais et ristournes",
    text: `Les tarifs proposés comprennent les rabais et ristournes que la société Destock BTP SAS 
    serait amenée à octroyer compte tenu de ses résultats ou de la prise en charge par l'acheteur de
    certaines prestations.`,
  },
  {
    titre: "Clause n° 4 : Escompte",
    text: `Aucun escompte ne sera consenti en cas de paiement anticipé.`,
  },
  {
    titre: "Clause n° 5 : Modalités de paiement",
    text: `Le règlement des abonnements ainsi que les achats ponctuels s'effectuent par carte bancaire
    (via le système Stripe, voir conditions). Lors de l'enregistrement de la commande, l'acheteur devra
    payer la totalité du montant global de la facture, le solde devant être payé avant utilisation de la dite prestation.`,
  },
  {
    titre: "Clause n° 6 : Clause de réserve de propriété",
    text: `La société Destock BTP SAS conserve la propriété des prestations vendues jusqu'au paiement
    intégral du prix, en principal et en accessoires. À ce titre, si l'acheteur fait l'objet d'un redressement
    ou d'une liquidation judiciaire, la société Destock BTP SAS se réserve le droit de revendiquer, dans le cadre
    de la procédure collective, les prestations vendues et restées impayées.`,
  },
  {
    titre: "Clause n° 7 : Livraison",
    text: `La livraison est effectuée par la mise en place instantanée des prestations auxquelles l’acheteur
    a souscrit. Le délai de livraison indiqué lors de l'enregistrement de la commande n'est donné qu'à titre 
    indicatif et n'est aucunement garanti.
    Par voie de conséquence, tout retard raisonnable dans la livraison des produits ne pourra pas donner lieu
    au profit de l'acheteur à :
    L’allocation de dommages et intérêts ;
    L’annulation de la commande.
    En cas de prestations manquantes ou non fonctionnelles, l'acheteur devra formuler toutes les réserves nécessaires
    au service technique à réception desdites prestations. Ces réserves devront être, en outre, confirmées par écrit 
    dans les cinq jours suivant la livraison, par messagerie directe via le site BtpGO.`,
  },
  {
    titre: "Clause n° 8 : Force majeure",
    text: `La responsabilité de la société Destock BTP SAS ne pourra pas être mise en œuvre si la 
    non-exécution ou le retard dans l'exécution de l'une de ses obligations décrites dans les présentes
    conditions générales de vente découle d'un cas de force majeure. À ce titre, la force majeure 
    s'entend de tout événement extérieur, imprévisible et irrésistible au sens de l'article 1148 du 
    Code civil.`,
  },
  {
    titre: "Clause n° 9 : Tribunal compétent",
    text: `Tout litige relatif à l'interprétation et à l'exécution des présentes conditions générales
    de vente est soumis au droit français.À défaut de résolution amiable, le litige sera porté devant
    le Tribunal de commerce d'Ajaccio (lieu du siège social)`,
  },
];
