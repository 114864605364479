  import gmao from "../../assets/img/blog/gmao.jpg"
  import maintenance from "../../assets/img/blog/maintenance.jpg"
  import smartBuilding from "../../assets/img/blog/smartBuilding.jpg"
  import ai from "../../assets/img/blog/ai.jpg"
  import sousTraitance from "../../assets/img/blog/sous_traitance.jpg"
  import ca from "../../assets/img/blog/ca.jpg"
  import futur from "../../assets/img/blog/futur.jpg"
  import plateform from "../../assets/img/blog/plateform.jpg"
  import eco from "../../assets/img/blog/eco.jpg"

  export const cards = [
    {
      name: "Une GMAO c'est quoi?",
      text: ` Gestion de maintenance assité par ordinateurLa GMAO, ou Gestion de Maintenance 
       Assistée par Ordinateur, désigne une solution complète pour la planification et la gestion des activités de 
       maintenance. La GMAO est un système informatique qui permet de surveiller et de gérer les activités de maintenance,
       et de surveiller leur bon fonctionnement. Elle aide à organiser et à optimiser les interventions de maintenance préventive
       et corrective.La GMAO est un outil de gestion puissant qui permet aux entreprises de surveiller et de gérer leurs actifs à 
       l'aide d'un système intelligent intégré. Il est conçu pour faciliter la prise de décisions concernant les actions à entreprendre
       pour maintenir et améliorer les performances des actifs.La GMAO fournit une interface conviviale et facile à utiliser pour la gestion
       de la maintenance. Elle offre une vue d'ensemble complète des activités en cours et permet aux utilisateurs de planifier, de documenter
       et de suivre toutes les activités liées à la maintenance. Elle fournit également des informations détaillées sur les actifs, les coûts
       associés et leurs performances.La GMAO permet également aux entreprises de surveiller en temps réel les performances et la santé de leurs
       actifs. Il fournit des informations précises sur leur état et leur fonctionnement, et aide à identifier les problèmes et à prendre les
       mesures appropriées pour les corriger.De plus, la GMAO fournit aux utilisateurs des rapports détaillés sur toutes les activités de
       maintenance et des outils pour la gestion des stocks. Cela permet aux entreprises de planifier et de gérer efficacement leurs 
       opérations de maintenance et de réduire les coûts et les temps d'arrêt.Enfin, la GMAO offre également des fonctionnalités
       avancées telles que l'analyse prédictive et l'intelligence artificielle pour améliorer la prise de décision et réduire les risques
       et les coûts liés à la maintenance.La GMAO est un outil très utile pour les entreprises qui souhaitent optimiser leurs activités de
       maintenance et améliorer leurs performances. Il offre une vue d'ensemble complète des activités de maintenance et des outils de gestion 
       avancés pour aider les utilisateurs à prendre les meilleures décisions et à réduire leurs coûts et leurs temps d'arrêt.`,
       imageUrl: gmao,
    },
    {
      name: "les 10 raisons d'utiliser une GMAO pour gestion des maintenance assitée par ordinateur",
      text: `Les avantages de l'utilisation d'une GMAO pour la gestion des maintenances assistée par ordinateur sont nombreux. Une GMAO est un système  
      informatisé qui permet de faciliter la planification des maintenances, de gérer les stocks et de suivre les performances des machines. Si vous 
      êtes à la recherche d'une solution pour gérer vos activités de maintenance, l'utilisation d'une GMAO peut être une excellente option. Voici  
      10 raisons pour lesquelles vous devriez envisager l'utilisation d'une GMAO pour la gestion des maintenances assistée par ordinateur :1. Une GMAO 
      peut vous aider à mieux gérer vos opérations de maintenance. Elle offre des fonctionnalités de planification et de suivi des opérations de maintenance 
      qui peuvent vous aider à améliorer la productivité et à réduire les coûts. Elle peut également vous aider à surveiller les performances des machines,  
      à surveiller le temps de maintenance et à surveiller l'utilisation des pièces détachées.2. Une GMAO peut vous aider à améliorer la sécurité de vos 
      installations. Elle peut vous aider à identifier les risques et à évaluer les procédures de sécurité et de protection des machines. Elle peut 
      également vous aider à surveiller les conditions de travail et à mettre en œuvre des mesures correctives pour réduire les risques.3. Une GMAO  
      peut vous aider à améliorer la qualité des produits et services. En utilisant une GMAO, vous pouvez surveiller les performances des machines  
      et améliorer leur durée de vie. Vous pouvez également améliorer les résultats des tests et effectuer des contrôles qualité plus fréquents.4.  
      Une GMAO peut vous aider à améliorer la planification et la gestion des stocks. Elle peut vous aider à surveiller les niveaux de stock et à  
      planifier les commandes de pièces détachées. Elle peut également vous aider à prévoir les éventuels besoins en pièces détachées pour assurer  
      la continuité des opérations.5. Une GMAO peut vous aider à améliorer la gestion des informations. Elle offre des fonctionnalités de gestion des 
      informations qui peuvent vous aider à organiser et à analyser les données relatives aux activités de maintenance.6. Une GMAO peut vous aider à  
      améliorer les relations avec les clients. Elle peut vous aider à fournir des informations précises et à jour sur les opérations de maintenance  
      et à communiquer avec les clients de manière efficace.7. Une GMAO peut vous aider à améliorer la gestion des documents. Elle peut vous aider à  
      organiser et à gérer les informations nécessaires à la gestion des documents et à la gestion des processus.8. Une GMAO peut vous aider à améliorer  
      la gestion des coûts. Elle peut vous aider à surveiller les coûts et à évaluer les économies réalisées grâce aux opérations de maintenance.9.  
      Une GMAO peut vous aider à améliorer la gestion des ressources. Elle peut vous aider à planifier et à organiser les ressources humaines  
      et matérielles nécessaires pour assurer la continuité des opérations.10. Une GMAO peut vous aider à améliorer le reporting. Elle peut vous 
      aider à produire des rapports détaillés et à fournir des informations précises sur les activités de maintenance.`,
      imageUrl: maintenance,
    },
    {
      name: "Les interetes du smart bulding en france ",
      text: `Depuis quelques années, le concept de « Smart Building » est devenu une tendance de plus en plus importante dans l’industrie de la construction 
      en France. Ce concept se réfère à la construction et à l’utilisation de bâtiments intelligents qui sont conçus et gérés à l’aide d’une combinaison d’outils
      technologiques et de gestion intégrés. Dans un tel bâtiment, tous les systèmes sont connectés entre eux, ce qui permet à l’utilisateur de contrôler et de surveiller
      tous les aspects de sa propriété depuis un seul et unique point de contrôle.Les avantages offerts par les bâtiments intelligents sont nombreux, tels que la réduction 
      des coûts et une meilleure efficacité énergétique. Les bâtiments intelligents sont généralement conçus pour offrir une plus grande sécurité et confort aux utilisateurs,
      un meilleur contrôle des coûts et une meilleure gestion des risques. De plus, les bâtiments intelligents peuvent aussi offrir des avantages en termes de qualité 
      de l'air intérieur et de la protection de l'environnement. Avec l'augmentation des coûts de l'énergie et des matériaux de construction, les bâtiments intelligents
      peuvent aider les propriétaires à réduire considérablement leurs coûts de fonctionnement. Les bâtiments intelligents peuvent être conçus pour s'adapter aux conditions
      météorologiques environnantes et peuvent être configurés pour répondre à des besoins spécifiques. Les systèmes intégrés, tels que les systèmes de gestion de l'énergie
      et de contrôle de la température, peuvent aider à réduire les coûts de fonctionnement et à améliorer la sécurité et le confort des utilisateurs. En outre, les bâtiments
      intelligents peuvent aider à améliorer la qualité de l'air intérieur, en régulant et en contrôlant les niveaux de CO2 et d'autres particules nocives qui peuvent être 
      nocives pour la santé des occupants. Les bâtiments intelligents peuvent également être conçus pour réduire l'impact environnemental, en offrant des systèmes d'éclairage 
      à faible consommation d'énergie et des systèmes de chauffage et de refroidissement plus efficaces.Les bâtiments intelligents peuvent également offrir aux occupants une 
      meilleure sécurité et une meilleure gestion des risques. Les systèmes intégrés de sécurité peuvent aider à protéger les utilisateurs contre les cambriolages et les 
      intrusions, et les systèmes de gestion des risques peuvent offrir un meilleur contrôle des coûts et une meilleure préparation aux situations d'urgence. En outre, 
      les bâtiments intelligents peuvent offrir des systèmes de surveillance pour aider à prévenir les accidents et les catastrophes. Les bâtiments intelligents peuvent 
      également offrir aux propriétaires et aux occupants des avantages sociaux et financiers. Les bâtiments intelligents peuvent offrir des fonctionnalités telles que des
      systèmes de gestion des réservations et des paiements, des systèmes de gestion des locations et des contrôles de sécurité pour aider à améliorer la sécurité et le 
      confort des occupants. Les bâtiments intelligents peuvent également être conçus pour offrir un meilleur contrôle des coûts, en réduisant les coûts de maintenance et 
      d'utilisation et en offrant des solutions durables et rentables pour les propriétaires et les occupants. Enfin, les bâtiments intelligents peuvent offrir une plus 
      grande flexibilité aux propriétaires et aux occupants. Les bâtiments intelligents peuvent être conçus pour être facilement adaptés aux besoins et aux préférences des
      occupants, ce qui permet à la propriété de s'adapter aux changements des tendances et des conditions météorologiques. Les bâtiments intelligents peuvent également 
      offrir des technologies et des systèmes avancés pour aider à améliorer la qualité de vie des occupants et leur permettre de mieux profiter de leur propriété. Avec 
      tous ces avantages offerts par les bâtiments intelligents, il n'est pas surprenant que le concept soit devenu si populaire en France. Les bâtiments intelligents 
      offrent aux propriétaires et aux occupants une plus grande sécurité et confort et une meilleure gestion des coûts. De plus, les bâtiments intelligents peuvent être
      conçus pour offrir une plus grande sécurité et une meilleure gestion des risques, une meilleure qualité de l'air intérieur et une plus grande protection de l'environnement.
      Enfin, les bâtiments intelligents peuvent offrir une plus grande flexibilité aux propriétaires et aux occupants et leur permettre de mieux profiter de leur propriété.`,
      imageUrl: smartBuilding,
    },
    {
      name: "L'intelligence artificielle dans le secteur du btp",
      text: `L’intelligence artificielle (IA) est en train de révolutionner le secteur du bâtiment et des travaux publics (BTP). En effet, elle est devenue une technologie
      très précieuse pour les entrepreneurs qui cherchent à améliorer l'efficacité, la qualité et la productivité de leurs projets. De plus en plus de sociétés de BTP se tournent
      vers l’intelligence artificielle pour optimiser leurs processus et leurs opérations. Dans cet article, nous examinerons en détail comment l’IA est utilisée dans le secteur
      du BTP et comment elle peut aider à améliorer le rendement et la qualité des projets.Tout d'abord, l'utilisation de l'IA dans le secteur du BTP s'inscrit dans le cadre 
      de l'industrie 4.0, qui se définit par l'utilisation des technologies numériques pour améliorer le rendement et la qualité des processus et des opérations. L’intelligence
      artificielle est l’un des outils principaux de l’industrie 4.0, et elle est utilisée dans le secteur du BTP pour automatiser certaines tâches, analyser les données et 
      prendre des décisions plus intelligentes. Elle peut également aider à réduire les coûts et à améliorer la sécurité et la qualité des projets.Les entreprises de BTP 
      utilisent l’intelligence artificielle pour prendre de meilleures décisions et ainsi améliorer leurs opérations. Par exemple, les systèmes de planification et de gestion
      de la production peuvent être améliorés grâce à l’utilisation de l’IA. Ces systèmes peuvent analyser les données et prendre des décisions plus intelligentes en matière
      de planification, de gestion des stocks et de gestion des ressources. De plus, les systèmes de gestion de la qualité peuvent être améliorés en utilisant l’intelligence
      artificielle pour analyser les données et prendre des décisions plus précises en matière de qualité et de conformité.L’intelligence artificielle est également utilisée 
      pour améliorer la sécurité des chantiers et des projets de construction. Les outils d'IA peuvent analyser les données en temps réel et prendre des décisions plus rapides
      et plus précises pour assurer la sécurité des travailleurs et des lieux de travail. De plus, l’IA peut aider à évaluer les risques et à prévenir les accidents et les 
      blessures.L’utilisation de l’intelligence artificielle dans le secteur du BTP peut également aider les entrepreneurs à améliorer leurs opérations et leurs processus. 
      Par exemple, les outils d'IA peuvent aider à améliorer l'estimation des coûts des projets, à améliorer la gestion des approvisionnements et à accélérer le cycle de 
      production. De plus, les systèmes d'IA peuvent fournir des informations précises sur le calendrier et le budget des projets, ce qui permet aux entrepreneurs de prendre
      des décisions plus éclairées.Enfin, l’utilisation de l’intelligence artificielle dans le secteur du BTP peut aider les entreprises à améliorer leurs relations avec 
      leurs clients. Les outils d’IA peuvent aider à gérer la communication et les relations entre les clients et les entreprises, à fournir des informations précises sur 
      le calendrier et le budget des projets et à améliorer la satisfaction des clients. De plus, l’IA peut aider les entreprises à mieux comprendre leurs clients et à fournir
      des solutions plus personnalisées.En résumé, l'utilisation de l'intelligence artificielle dans le secteur du BTP est en train de révolutionner le secteur. Les outils
      d'IA peuvent aider les entrepreneurs à améliorer l'efficacité, la qualité et la productivité de leurs projets. Ils peuvent également aider à améliorer la sécurité et
      la qualité des projets, à réduire les coûts et à améliorer leurs relations avec leurs clients. L'IA est donc un outil incontournable pour les entreprises de BTP qui 
      cherchent à améliorer leurs opérations et leurs processus.`,
      imageUrl: ai,
    },
    {
      name: "La sous-traitance dans le secteur du btp en france",
      text: `La sous-traitance dans le secteur du BTP en FranceLa sous-traitance est un terme utilisé pour désigner le fait de confier à une autre entreprise certains 
      éléments d’un projet. Dans le secteur du BTP (Bâtiment et Travaux Publics), la sous-traitance est un moyen efficace pour les entreprises de réduire les coûts, d’améliorer
      leur productivité et de se concentrer sur leurs activités principales.L’utilisation de la sous-traitance dans le secteur du BTP est devenue de plus en plus courante en
      France ces dernières années. En effet, la sous-traitance permet aux entreprises du BTP de réduire leurs coûts en externalisant certaines tâches à des sous-traitants 
      spécialisés, mais elle permet également aux entreprises de se concentrer sur leurs activités principales.Par exemple, une entreprise de construction peut choisir de 
      sous-traiter le gros œuvre à une entreprise spécialisée dans ce domaine, ce qui lui permet de se concentrer sur le reste du projet et d’accélérer le processus. De même, 
      une entreprise de génie civil peut choisir de sous-traiter la finition des travaux à une entreprise spécialisée, ce qui lui permet de se concentrer sur les aspects 
      techniques du projet et de réduire ses coûts.De plus, la sous-traitance peut être un moyen pour les entreprises du BTP d’accroître leur productivité. En effet, en 
      externalisant certaines tâches, les entreprises peuvent se concentrer sur leurs domaines d’expertise et peuvent mieux gérer leurs ressources, ce qui permet de réduire
      les délais de production.Enfin, la sous-traitance peut également être un moyen pour les entreprises du BTP de réduire leurs risques. En effet, en sous-traitant certaines
      tâches, les entreprises peuvent réduire leurs risques en cas de litige ou de défaut de fabrication.En somme, la sous-traitance est un moyen efficace pour les entreprises 
      du BTP de réduire leurs coûts, d’améliorer leur productivité et de réduire leurs risques. Toutefois, il est important de noter que le choix des sous-traitants doit être 
      effectué avec soin afin de s’assurer que les entreprises choisissent des sous-traitants fiables et compétents. De plus, il est également important de veiller à ce que les 
      sous-traitants soient correctement rémunérés pour leurs services afin de s’assurer que leurs travaux sont effectués dans les délais et avec le niveau de qualité requis.`,
      imageUrl: sousTraitance,
    },
    {
      name: "Le marché du btp en france, son chiffre d'affaire et le pourcentage du pib en franceIntroduction",
      text: `Le marché du BTP en France est l'un des plus grands et des plus importants marchés du secteur de la construction. Il représente environ 10% du PIB français,
      ce qui le place au deuxième rang des secteurs économiques du pays. Le marché du BTP en France est très diversifié et comprend des acteurs variés, des entrepreneurs aux
      grands groupes et des PME. Au cours des dernières années, le marché du BTP en France a connu une croissance soutenue et a généré des revenus considérables pour le secteur.
      Le marché du BTP en France est composé de plusieurs secteurs. Le secteur des travaux publics regroupe les travaux de construction et réhabilitation des routes, des voies
      ferrées et des infrastructures urbaines. Il inclut également les travaux de génie civil et les projets de mise en valeur des terres agricoles. Le secteur du bâtiment 
      inclut les travaux de construction et de rénovation des bâtiments publics et privés. Le secteur des travaux spécialisés comprend les travaux de plomberie, de chauffage
      et de climatisation ainsi que les travaux de rénovation énergétique. Enfin, le secteur de l'industrie du bâtiment comprend la fabrication et la commercialisation 
      des matériaux et des équipements de construction.Le marché du BTP en France est très concurrentiel et ses principaux acteurs sont des entreprises familiales qui ont
      des structures opérationnelles très flexibles. La plupart des entreprises du BTP sont des PME et des entrepreneurs indépendants. Les grands groupes du secteur sont
      généralement des entreprises mondiales qui sont présentes dans de nombreux pays. Les principaux acteurs du marché du BTP en France sont Bouygues Construction, Vinci, 
      Vinci Construction et Eiffage.Le marché du BTP en France est l'un des principaux secteurs de l'économie française. En 2019, le secteur a généré un chiffre d'affaires 
      de près de 200 milliards d'euros, en hausse de 3 % par rapport à 2018. Le secteur emploie environ 2,5 millions de personnes, soit environ 10 % de la population active 
      française. De plus, le secteur est responsable de plus de 10 % de la contribution des entreprises à l'impôt sur le revenu des sociétés.Le marché du BTP en France est
      fortement influencé par les décisions politiques et réglementaires et par les conditions économiques. Les coûts de matériaux et de main-d'œuvre sont des facteurs 
      importants qui influencent le secteur. En outre, la demande pour les produits et services du BTP est très sensible aux fluctuations des prix de l'immobilier et des
      taux d'intérêt.Le secteur du BTP en France est fortement soutenu par les différents organismes publics, notamment le ministère de la Transition écologique et 
      solidaire et les collectivités territoriales. Ces organismes fournissent des financements et des aides aux entreprises du secteur et encouragent la mise en place 
      de projets innovants et durables.Le secteur du BTP en France est également soutenu par des programmes de recherche et développement visant à encourager l'innovation
      et l'utilisation des technologies vertes et durables. Par exemple, le programme « Ma Prime Rénov », lancé en 2020, a pour but d'encourager les particuliers à rénover 
      leur logement, en particulier dans les zones urbaines.Le marché du BTP en France représente environ 10 % du PIB français, ce qui en fait l'un des secteurs les plus 
      importants de l'économie. En outre, le secteur contribue à l'emploi et à la croissance économique. En effet, selon les estimations du ministère de l'Économie et des
      Finances, le secteur a créé plus de 300 000 emplois directs et indirects en 2019.ConclusionLe marché du BTP en France est l'un des secteurs économiques les plus
      importants du pays et il a connu une croissance soutenue au cours des dernières années. Le secteur représente environ 10 % du PIB français et contribue à la
      création d'emplois et à la croissance économique. Les principaux acteurs du marché sont des entreprises familiales et des grands groupes nationaux et internationaux.
      Le marché est fortement influencé par les décisions politiques et réglementaires ainsi que par les conditions économiques et les fluctuations des prix de l'immobilier.
      Le secteur est soutenu par les organismes publics et des programmes de recherche et développement visant à encourager l'innovation et l'utilisation des technologies vertes
      et durables.`,
      imageUrl: ca,
    },
      {
        name: "Le futur du btp en france",
        text: `Le secteur du BTP en France est un secteur en pleine mutation. Alors que les secteurs de la construction et de l'aménagement du territoire ont connu 
        des années difficiles, ils sont aujourd'hui à la croisée des chemins. Les nouvelles technologies sont en train de révolutionner le secteur et de nouvelles méthodes
        de travail sont mises en place. Dans ce contexte, les professionnels du BTP sont à la recherche de nouvelles solutions pour faire face aux défis de demain.
        Dans les prochaines années, le secteur du BTP devra relever de nombreux défis en matière de développement durable. La transition énergétique et la lutte
        contre le changement climatique font partie des principales préoccupations des professionnels du BTP. Dans ce contexte, de nouvelles technologies et de 
        nouvelles méthodes de travail seront nécessaires pour atteindre les objectifs de développement durable. La mise en place de nouvelles technologies, comme
        les matériaux biosourcés, le recyclage des matériaux, l'utilisation de la géothermie et de l'énergie solaire, ainsi que la mise en place de nouvelles méthodes
        de construction respectueuses de l'environnement seront indispensables pour atteindre ces objectifs.Un autre défi important pour le secteur du BTP est la 
        numérisation. En effet, les nouvelles technologies et l'utilisation des données sont en train de révolutionner le secteur. De plus en plus d'entreprises du
        BTP utilisent des outils de numérisation pour faciliter leur travail et améliorer leur productivité. L'utilisation de drones, de robots, de systèmes de 
        vision et de capteurs pour surveiller le chantier, ainsi que l'utilisation de logiciels pour gérer les projets et les processus, sont des exemples de 
        technologies qui sont utilisées pour améliorer la productivité des entreprises du BTP.Le secteur du BTP devra également relever le défi de la sécurité 
        et de la santé au travail. La sécurité des chantiers de construction et des ouvriers est une préoccupation prioritaire pour les professionnels du BTP. 
        La mise en place de nouvelles méthodes de travail et de nouvelles technologies, comme les robots et les drones, permettra d'améliorer la sécurité des 
        ouvriers et des chantiers. De plus, les professionnels du BTP devront mettre en place de nouvelles mesures pour prévenir les accidents du travail et les 
        maladies professionnelles.Enfin, le secteur du BTP devra faire face à la concurrence internationale. Les entreprises du BTP doivent trouver des moyens de se
        différencier et de se démarquer des autres entreprises du secteur. Un des moyens de le faire est d'innover et d'adopter des technologies innovantes et des
        méthodes de travail plus efficaces. Les entreprises du BTP devront également trouver de nouveaux marchés et de nouvelles sources de financement pour investir
        dans leur développement.Le futur du BTP en France est donc prometteur, mais il est loin d'être assuré. Les professionnels du BTP devront relever de 
        nombreux défis pour s'adapter aux nouvelles technologies, à la concurrence internationale et à la transition énergétique. Ils devront également trouver
        des moyens de se différencier et de se démarquer des autres entreprises du secteur. Les professionnels du BTP devront donc s'adapter rapidement aux 
        nouveaux défis et aux nouvelles technologies pour pouvoir réussir à relever ces défis et à profiter des opportunités offertes par le futur du BTP en France.`,
        imageUrl: futur,
      },
      {
        name: "les 10 plateforme pour trouver des artisans du batiments",
        text: `Les artisans du bâtiment sont des professionnels indispensables pour les travaux d’aménagement et de réparation. Ils sont en mesure d’effectuer diverses
        tâches telles que le montage de meubles, la installation de plomberie ou encore l’application de couches de peinture. Pour trouver des artisans qualifiés et professionnels,
        de nombreuses plateformes en ligne peuvent être consultées. Nous allons vous présenter dans cet article les 10 meilleures plateformes pour trouver des artisans 
        du bâtiment.Tout d’abord, nous commencerons par Les Projets Locaux qui est une plateforme permettant aux particuliers et aux entreprises de trouver des artisans
        du bâtiment qualifiés. La plateforme offre un large choix de professionnels pouvant répondre aux besoins des clients. Il est possible de rechercher des artisans
        par code postal, spécialité ou encore nom. Les Projets Locaux propose également des services tels que des devis gratuits et des garanties écrites pour les
        travaux.Ensuite, vous trouverez également le site internet Allo-Plombier qui propose un réseau d’artisans du bâtiment spécialisés dans les travaux de plomberie.
        Il est possible de trouver des plombiers qualifiés et expérimentés dans toutes les régions de France. Allo-Plombier offre également des services tels que des 
        devis gratuits et des garanties d’artisanat.Vous pouvez également vous tourner vers la plateforme Artisanat.fr qui propose un large choix de professionnels 
        du bâtiment spécialisés dans différents domaines. La plateforme permet aux clients de trouver des artisans qualifiés et expérimentés dans leur région. 
        Artisanat.fr offre également des services tels que des devis gratuits et des garanties écrites pour les travaux.Le site internet Artisans du Monde est 
        également un excellent endroit pour trouver des artisans qualifiés et expérimentés du bâtiment. La plateforme propose un large choix de professionnels 
        spécialisés dans différents domaines tels que la plomberie, l’électricité ou encore la menuiserie. Artisans du Monde offre également des services comme 
        des devis gratuits et des garanties pour les travaux.Vous pouvez également consulter la plateforme Trouver un Artisan qui est une plateforme spécialisée 
        dans la recherche d’artisans du bâtiment. Il est possible de trouver des artisans qualifiés et expérimentés dans toutes les régions de France. Trouver un 
        Artisan offre des services tels que des devis gratuits et des garanties pour les travaux.Le site internet Bâtiment Pro est une autre plateforme permettant 
        aux clients de trouver des artisans qualifiés et expérimentés dans leurs régions. La plateforme propose un large choix de professionnels spécialisés dans 
        différents domaines tels que la plomberie, l’électricité ou encore la menuiserie. Bâtiment Pro offre également des services tels que des devis gratuits et 
        des garanties pour les travaux.Vous pouvez également consulter la plateforme Artisans du Bâtiment qui est un site internet spécialisé dans la recherche 
        d’artisans du bâtiment. Il est possible de trouver des artisans qualifiés et expérimentés dans toutes les régions de France. Artisans du Bâtiment offre des
        services tels que des devis gratuits et des garanties pour les travaux.Le site internet Artisans à votre service est également un excellent endroit pour 
        trouver des artisans qualifiés et expérimentés dans le bâtiment. La plateforme offre un large choix de professionnels spécialisés dans différents domaines 
        tels que la plomberie, l’électricité ou encore la menuiserie. Artisans à votre service propose également des services tels que des devis gratuits et des 
        garanties pour les travaux.Vous trouverez également le site internet Artisans du Bâtiment qui est une plateforme spécialisée dans la recherche d’artisans
        du bâtiment. Il est possible de trouver des artisans qualifiés et expérimentés dans toutes les régions de France. Artisans du Bâtiment offre des services 
        tels que des devis gratuits et des garanties pour les travaux.Enfin, vous pouvez également vous tourner vers la plateforme Bâtiment Pro qui est une plateforme
        spécialisée dans la recherche d’artisans qualifiés et expérimentés dans le bâtiment. La plateforme propose un large choix de professionnels spécialisés dans 
        différents domaines tels que la plomberie, l’électricité ou encore la menuiserie. Bâtiment Pro propose également des services tels que des devis gratuits et 
        des garanties pour les travaux.Vous savez maintenant quelles sont les 10 meilleures plateformes pour trouver des artisans du bâtiment. Quel que soit le type 
        de travail que vous souhaitez faire, vous pouvez être sûr de trouver un artisan qualifié et expérimenté. N’hésitez pas à consulter ces plateformes et à comparer 
        les différents services proposés afin de trouver le meilleur artisan pour votre projet.`,
        imageUrl: plateform,
      },
      {
        name: "La part des artisants du btp , le pourcentage de chaque specialisation, leur rôle dans l’économie",
        text: `L’artisanat est un terme générique qui englobe toute une série d’activités liées à la production et à la transformation des matières premières. Il englobe
        les secteurs de l’agriculture, de la pêche, de la chasse et de la sylviculture, ainsi que l’industrie manufacturière, l’artisanat et les services. Plus spécifiquement,
        l’artisanat du bâtiment et des travaux publics (BTP) fait référence à l’ensemble des métiers et des activités liés à la construction et à la rénovation d’ouvrages 
        immobiliers. En France, le secteur du BTP représente environ 10% du produit intérieur brut (PIB) et emploie près de 10% de la population active.L’artisanat du BTP 
        est composé d’une variété de métiers spécialisés, qui sont généralement répartis en quatre catégories principales : les métiers de l’architecture et de l’ingénierie,
        les métiers du génie civil, les métiers de l’industrie du bâtiment et les métiers du second œuvre. Les métiers de l’architecture et de l’ingénierie sont le plus souvent
        liés à la conception et à la planification des projets. Les métiers du génie civil sont généralement liés à la construction et à la rénovation des ouvrages immobiliers, 
        tels que les routes, les ponts et les bâtiments. Les métiers de l’industrie du bâtiment sont liés à la production et à la livraison des produits nécessaires à la 
        construction et à la rénovation des ouvrages immobiliers, tels que les matériaux de construction, les équipements et les outils. Enfin, les métiers du second œuvre 
        sont liés à l’installation et à l’entretien des ouvrages immobiliers, tels que les menuiseries, les revêtements de sol et les équipements électriques et sanitaires.En
        ce qui concerne la part des artisans du BTP dans l’économie française, il est estimé qu’environ 800 000 personnes travaillent dans ce secteur. Ces travailleurs 
        représentent environ 5% de la population active française et représentent plus de 5% du PIB. La majorité des artisans du BTP sont des entrepreneurs indépendants, qui sont
        responsables de leur propre entreprise et qui travaillent directement avec des clients. En outre, il existe également des entreprises plus grandes qui peuvent embaucher
        des artisans et des apprentis pour aider à la réalisation de projets plus importants.Selon le type de métier, les artisans du BTP peuvent être regroupés en pourcentages
        différents. Par exemple, environ 7% des artisans du BTP travaillent dans les métiers de l’architecture et de l’ingénierie. En outre, environ 12% des artisans du BTP 
        sont spécialisés dans les métiers du génie civil et environ 18% sont spécialisés dans les métiers de l’industrie du bâtiment. Enfin, environ 63% des artisans du BTP 
        sont spécialisés dans les métiers du second œuvre.Le rôle des artisans du BTP est essentiel à l’économie française. Ils sont responsables de la construction et de la 
        rénovation des ouvrages immobiliers, qui sont à la base de l’économie française. En outre, ils sont également responsables de la production et de la livraison des produits
        et des matériaux nécessaires à la construction et à la rénovation des ouvrages immobiliers. Enfin, ils sont également responsables de l’installation et de l’entretien
        des ouvrages immobiliers.En conclusion, l’artisanat du BTP est un secteur essentiel à l’économie française. Il représente environ 10% du PIB et emploie environ 10% de 
        la population active. Il est composé d’une variété de métiers spécialisés, qui sont généralement répartis en quatre catégories principales. En outre, le secteur emploie
        environ 800 000 artisans du BTP qui sont répartis en pourcentages différents selon le type de métier. Enfin, le rôle des artisans du BTP est essentiel à l’économie 
        française, car ils sont responsables de la construction et de la rénovation des ouvrages immobiliers, de la production et de la livraison des produits et des matériaux 
        nécessaires à la construction et à la rénovation des ouvrages immobiliers et de l’installation et de l’entretien des ouvrages immobiliers.`,
        imageUrl: eco,
      },
  ];