import React from "react";
import logo from "assets/img/welcome/logo_btpGo_text.png";
import {
  NavItem,
  Nav,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./Welcome.css";
import Buttons from "components/Ui/Button";

function WelcomeNavMobile({ closeCollapse, menu_class }) {
  const navItems = [
    {
      id: 1,
      icon: "ni ni-key-25",
      text: "Professionnel BTP",
      to: "/welcomeArtisans",
    },
    {
      id: 2,
      icon: "ni ni-circle-08",
      text: "Client",
      to: "/welcomeProperty",
    }];

  return (
    <>
      <div className={menu_class} navbar>
        <Link to="/">
          <img
            src={logo}
            alt="..."
            style={{ width: "5rem", height: "5rem", marginLeft: "15px", marginTop: "15px" }}
          />
          <Buttons
            buttonClass="logine mb-5"
            linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH}
            buttonText="Connectez vous c'est gratuit"
          />
        </Link>

        <Nav
          className="ml-auto align-items-center mt-6"
          navbar
          // onClick={closeCollapse}
          style={{ height: "60rem", fontSize: "0.8rem" }}
        >
          {navItems.map((item) =>
            <NavItem
              style={{
                color: "#525f7f",
                position: "relative",
                margin: "0px auto 20px",
              }}
            >
              <NavLink
                to={item.to}
                tag={Link}
                style={{
                  display: "block",
                  padding: "0.25rem 0.75rem",
                  fontSize: "24px",
                }}
              >
                <span className="nav-link-inner--text">{item.text}</span>
              </NavLink>
            </NavItem>)}

          <NavItem
            style={{
              color: "#525f7f",
              position: "relative",
              margin: "0px auto 20px",
            }}
          >
            <NavLink
              key="3"
              to={process.env.REACT_APP_WELCOME_PAGE_PATH}
              tag={Link}
              style={{
                display: "block",
                padding: "0.5rem 1rem",
                fontSize: "20px",
                borderBottom: "none",
                marginTop: "40px"
              }}
            >
              <button type="button" className="login-mobile-btn">Connectez vous c'est gratuit</button>
            </NavLink>
          </NavItem>

        </Nav>
      </div>
    </>
  );
}

export default WelcomeNavMobile;
