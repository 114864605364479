import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { cguData } from "../../components/Droits/CguData";

const Cgu = () => {
  return (
    <Container>
      <h1 className="d-flex justify-content-center">
        Conditions général d'utilisation
      </h1>
      <Row className="justify-content-center">
        {cguData.map((item, index) => (
          <Col lg="12" key={index}>
            <Card style={{ border: "none" }}>
              <CardBody>
                <CardTitle>{item.titre}</CardTitle>
                <CardText>{item.text}</CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Cgu;
