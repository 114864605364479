import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
// import AuthContext from "../../contexts/AuthProvider";
import { useHistory } from "react-router-dom";
import "./login.css";
import { accountService } from "utils/_services/account.service";


const Login = () => {
  // const { setAuth } = useContext(AuthContext);

  const [login, setLogin] = useState({
    userName: "bilel",
    password: "1234",
  });

  const onChange = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  let history = useHistory();
  const onSubmit = async (e) => {
    e.preventDefault();
    accountService
      .login(login)
      .then((res) => {
        accountService.saveToken(res.data.result.data.token);
        history.push("/ap/user-profile");
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="container-register" id="login">
        <div className=" row d-flex justify-content-center">
          <div className="col-md-6">
            <img
              className="img-login"
              alt="..."
              src={require("../../assets/img/welcome/login.png")}
            />
          </div>
          <div className="col-md-6">
            <Card className="bg-secondary shadow border-0">
              <CardBody
                className="px-lg-5 py-lg-5"
                style={{
                  background: "white",
                  boxShadow: "1px 0px 21px 2px #107aca",
                }}
              >
                <div className="text-center text-muted mb-4">
                  <h1 className="mb-6">Connexion</h1>
                </div>
                <Form onSubmit={onSubmit}>
                  <FormGroup>
                    <InputGroup className="input-login mb-3">
                      <Input
                        placeholder="Nom de connexion"
                        type="text"
                        name="userName"
                        id="userName"
                        autoComplete="username"
                        value={login.userName}
                        onChange={onChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-login">
                      <Input
                        placeholder="Mot de passe"
                        name="password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={login.password}
                        onChange={onChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                      style={{ background: "#9077ed" }}
                    >
                      Connexion
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
