import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Col, Row } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import "./patrimoine.css";
import { siteService } from "utils/_services/sites.service";
const PatrimoineModal = ({ show, handleClose }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cityName, setCityName] = useState("");
  const [countryName, setCountryName] = useState("");

  const handleSubmitPatrimoine = (e) => {
    e.preventDefault(e);

    const data = {
      name,
      phoneNumber,
      companyId: 1,
      address: {
        city: {
          name: cityName,
          country: {
            name: countryName,
          },
        },
      },
    };

    siteService
      .createSite(data)
      .then((res) => console.log("create site", res))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Modal show={show} onHide={show}>
        <Modal.Header>
          <Modal.Title> Nouveau site</Modal.Title>
          <Button color="danger" onClick={handleClose}>
            X
          </Button>
          {/* <div onClick={handleClose}>X</div> */}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitPatrimoine}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Nom
                  </label>
                  <Input
                    className="form-control-alternative"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="lastName"
                    id="lastName"
                    placeholder="Nom"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Téléphone mobile
                  </label>
                  <Input
                    className="form-control-alternative"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Téléphone mobile"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Ville
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-ville"
                    value={cityName}
                    onChange={(e) => setCityName(e.target.value)}
                    name="ville"
                    placeholder="ville"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Région
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-region"
                    value={countryName}
                    onChange={(e) => setCountryName(e.target.value)}
                    name="region"
                    placeholder="region"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              type="submit"
              color="success"
              style={{ justifyContent: "center" }}
            >
              Enregistrer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatrimoineModal;
