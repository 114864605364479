import React, { useEffect, useState } from "react";
import "./profil.css";

import { userService } from "utils/_services/user.service";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserStatus } from "features/slices/userSlice";
import { getCurrentUser } from "features/slices/userSlice";
import { fetchUser } from "features/slices/userSlice";
import { UserRoles } from "utils/constants";
import ViewProfile from "./ViewProfile";

const ViewPublicProfile = () => {
  const { userData } = useParams();
  const [searchedUser, setSearchedUser] = useState({});
  const currentUser = useSelector(getCurrentUser);
  const currentUserStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    userService.getUserById(userData).then((res) => {
      if (res !== undefined && res.data != null) {
        setSearchedUser(res.data)
      }
    });
  }, [userData]);

  useEffect(() => {
    if (currentUserStatus === "idle") {
      dispatch(fetchUser());
    }
  }, [currentUserStatus, dispatch]);

  return (
    <div className="mt-2">
      {currentUser.userRole === UserRoles.APATHEIA_ADMIN
        || (searchedUser.profileStatus === 1 && searchedUser.showInSearch)
        ? (<ViewProfile searchedUser={searchedUser} />)
        : <>
          <div className="view-public-profile container">
            <div className="d-flex flex-column justify-content-between align-items-center">
              <div className="p-2 d-flex flex-row">
                <h1>Pas de profil à afficher</h1>
              </div>
            </div>
          </div>

        </>
      }
    </div>
  );
};

export default ViewPublicProfile;
