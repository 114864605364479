import React, { useState, useEffect, createRef } from "react";
import Cropper from "react-cropper"
import Cancel from "../Forms/buttons/Cancel";
import Save from "../Forms/buttons/Save";
import "cropperjs/dist/cropper.css";
import "./Cropper.css";

const CustomCropper = (props) => {
    const { image, handleSave, handleCancel } = props;
    const cropperRef = createRef();
    const [cropData, setCropData] = useState("#");

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined" && cropperRef.current?.cropper.cropped) {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        }
    };

    useEffect(() => {
        if (cropData !== "#")
            handleSave(cropData)
        // eslint-disable-next-line
    }, [cropData]);

    return (
        <div style={{ width: "100%" }}>
            <Cropper
                ref={cropperRef}
                style={{ height: "100%", width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                zoomable={false}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
            />
            <br />
            <br />
            <Cancel
                field={"cancel"}
                title={"Annuler"}
                isReadOnly={false}
                handleCancel={handleCancel}
            />
            <Save
                field={"save"}
                title={"Sauvegarder"}
                isReadOnly={false}
                handleSave={getCropData}
            />
        </div>
    );
};

export default CustomCropper;
