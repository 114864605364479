import prospection from "../../../../assets/img/principal/prospectionArtisans.png";
import valorisation from "../../../../assets/img/principal/valorisationArtisan.png";
import digitalisation from "../../../../assets/img/principal/digitalisationArtisan.png";
import React from "react";
import Partenaires from "components/Ui/Partenaires";
import PhotoCard from "components/Ui/PhotoCard";
import "../../../Ui/fond.css";

const photoData = [
  {
    image: prospection,
    title: "Optimisez",
    text: `Optimisez votre temps pour trouvez des pros grace à de nombreux artisans fiables et riches => seach + scoring.`,
    lien: "En savoir plus ...",
  },
  {
    image: valorisation,
    title: "Valorisez",
    text: `Valorisez vos compétence par la creation gratuite un site gratuit & complet =>création de site gratuit et automatisé.`,
    lien: "En savoir plus ...",
  },
  {
    image: digitalisation,
    title: "Gagnez",
    text: `Gagnez en visibilité grâce à un site vitrine gratuit en moins de 10min => création de site.`,
    lien: "En savoir plus ...",
  },
];

const ProblematiqueArtisans = () => {
  return (
    <div className="container bgDefault">
      <div
        style={{
          alignItems: "center",
          height: "auto",
          maxWidth: "1275px",
          padding: "66px 45px",
        }}
      >
        <Partenaires />
      </div>
      <div className="col-md-12 mt-5 mb-4">
        <h1
          className="display-4 pb-4 d-flex justify-content-center"
          style={{ fontWeight: "bold", gap: "1%", color: "rgb(50,104,136)" }}
        >
          Construisez votre réseau grâce à ces outils simples et adaptés
        </h1>
      </div>
      <PhotoCard photoData={photoData} />
    </div>
  );
};

export default ProblematiqueArtisans;
