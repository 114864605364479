import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/btpGo.css";
import { I18nextProvider } from "react-i18next";

import { loadStripe } from "@stripe/stripe-js";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Welcome from "layouts/Welcome.js";
import Blog from "layouts/Blog.js";
import WelcomProperty from "layouts/WelcomProperty";
import WelcomeArtisans from "layouts/WelcomeArtisans";
import Completion from "components/Payment/Completion";
import { paymentService } from "utils/_services/payment.service";
import Empty from "layouts/Empty";
import withClearCache from "ClearCache";
import i18n from "./i18n";

const ClearCacheComponent = withClearCache(MainApp);

export default function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const [stripePromise, setStripePromise] = useState(null);

  const isCommingSoonPage = () => {
    console.log(process.env.REACT_APP_IS_COMMING_SOON === 'true');

    return process.env.REACT_APP_IS_COMMING_SOON;
  }

  React.useEffect(() => {
    paymentService.paymentConfig().then(async (response) => {
      // console.log(response);
      const { publishableKey } = await response.data;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  return (
    <I18nextProvider i18n={i18n("fr")}>
      <BrowserRouter >
        <Switch>
          <Route
            path="/welcomeArtisans"
            render={(props) => <WelcomeArtisans {...props} />}
          />
          {process.env.REACT_APP_IS_COMMING_SOON === 'true' && <Route path="/launch" render={(props) => <Empty {...props} />} />}
          <Route
            path="/welcomeProperty"
            render={(props) => <WelcomProperty {...props} />}
          />
          {/* <Route path="/condition-général-de-vente" render={(props) => <Welcome {...props} />} /> */}
          {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route path="/ap" render={(props) => <AdminLayout {...props} />} />}

          {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route
            path="/completion"
            render={(props) => <Completion stripePromise={stripePromise} />}
          />}
          <Route path="/blog" render={(props) => <Blog {...props} />} />
          {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route path="/admin" render={(props) => <AdminLayout {...props} />} />}
          {process.env.REACT_APP_IS_COMMING_SOON === 'false' && <Route path="/auth" render={(props) => <AuthLayout {...props} />} />}

          <Route exact path="/" render={(props) => <Welcome {...props} />} />
          <Route element={<Welcome />} />
        </Switch>
      </BrowserRouter>
    </I18nextProvider>
  );
}
