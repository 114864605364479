import React from "react";
import { Carousel, CarouselItem } from "reactstrap";

const CustomCarousel = (props) => {
  const { items, activeIndex } = props;

  const slides = items.map((item) => {
    return <CarouselItem key={item.id}>{item.component}</CarouselItem>;
  });

  return (
    <div>
      <Carousel
        next={() => {}}
        previous={() => {}}
        activeIndex={activeIndex}
        interval={false}
        keyboard={false}
      >
        {slides}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
