import React from "react";
import { Media } from "reactstrap";

const Comment = ({ postComment }) => (
  <Media className="mb-4t mt-2 ext-empty">
    <Media left>
      <Media left className="align-self-center mt-2">
        <img
          id={postComment.id}
          alt="profil"
          className="avatar"
          src={
            postComment.createdUser.photo
              ? postComment.createdUser.photo.url
              : "https://images.assetsdelivery.com/compings_v2/triken/triken1608/triken160800029.jpg"
          }
        />
      </Media>
    </Media>
    <Media body className="ml-2 mt-2 bg-gray-50 rounded">
      <div className="d-flex align-items-start">
        <div className="pl-2">
          <span className="d-flex text-dark font-weight-bold small text-capitalize">
            {postComment.createdUser.firstName}{" "}
            {postComment.createdUser.lastName}
          </span>
          <span className="d-flex small">
            {postComment.createdUser.job?.title},{" "}
            {postComment.createdUser.company?.name}
          </span>
        </div>
      </div>
      <p className="pl-2 mb-1 text-dark small">{postComment.content}</p>
    </Media>
    {/* <i className="fa fa-times justify-content-center align-self-center ml-2 cursor-pointer" ></i>    */}
  </Media>
);

export { Comment };
