import { useState } from "react";

const TextareaWithLimit = ({
  id,
  maxlength,
  value,
  onChange,
  name,
  placeholder,
  rowsCount,
}) => {
  const [currentLength, setCurrentLength] = useState(value?.length || 0);

  const onKeyUp = (e) => {
    setCurrentLength(e.target.value.length);
  };

  return (
    <>
      <textarea
        type="text"
        class="form-control"
        rows={rowsCount}
        id={id}
        name={name}
        maxLength={maxlength}
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={value}
        placeholder={placeholder}
      />
      <span class="mr-2 mt-n4 badge badge-primary float-right position-relative">
        {currentLength} / {maxlength}
      </span>
    </>
  );
};

export default TextareaWithLimit;
