import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getUserStatus, fetchUser } from 'features/slices/userSlice';

const useFetchUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);

  useEffect(() => {
    if (userStatus === 'idle') {
      dispatch(fetchUser());
    }
  }, [userStatus, dispatch]);

  return user;
};

export default useFetchUser;