import Axios from "./caller.service";
import { BillsMock } from "./BillsMock";

const getBillById = (id) => {
  return BillsMock.find(x => x.id === id);
}

let getAllBillsByUserId = (userId) => {
  const response = Axios.get("/api/invoice/all/" + userId);
  return response;
  // return BillsMock;
};

let addBill = (invoice) => {
  const response = Axios.post("/api/invoice/add",
    invoice
  );

  return response;
  // return BillsMock;
};

let updateBill = (invoice) => {
  const response = Axios.post("/api/invoice/update",
    invoice
  );

  return response;
  // return BillsMock;
};

export const billingService = {
  getAllBillsByUserId,
  getBillById,
  addBill,
  updateBill
};
