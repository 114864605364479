import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { cell, rowStyle, defaultBackgroundColor } from "./styles";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderLeftColor: cell.borderColor,
    borderLeftWidth: cell.borderWidth,
    borderRightColor: cell.borderColor,
    borderRightWidth: cell.borderWidth,
    borderBottomColor: cell.borderColor,
    borderBottomWidth: cell.borderWidth,
    alignItems: "center",
    height: cell.height,
    backgroundColor: defaultBackgroundColor.rows,
  },
  column: {
    borderRightColor: cell.borderColor,
    borderRightWidth: cell.borderWidth,
  },
});

// const getRowHeight = (row) => {
//   // Example: dynamically calculate height based on the content of the row
//   let baseHeight = 20; // Default base height
//   Object.keys(row).forEach((item) => {
//     if (row[item] && row[item].length > 100) {
//       baseHeight += 10; // Increase height for longer content
//     }
//   });
//   return baseHeight;
// };

const TableRow = React.memo(({ form, customCss = {} }) => {
  const lastIndex = form.headers.length - 1;
  const getCellStyles = (index, size) => {
    return index !== lastIndex
      ? [{ ...rowStyle, ...customCss.text }, { width: size }, styles.column]
      : [{ ...rowStyle, ...customCss.text }, { width: size }];
  };

  return form.rows?.map((row, rowIndex) => (
    <View
      style={{
        ...styles.container,
        // height: getRowHeight(row), // Dynamically set height here
        ...customCss.view,
      }}
      key={rowIndex.toString()}
    >
      {Object.keys(row).map((item, colIndex) => (
        <Text
          key={`${rowIndex}-${colIndex}`}
          style={getCellStyles(colIndex, form.headers[colIndex].size)}
        >
          {row[item] ? row[item] : ""}
        </Text>
      ))}
    </View>
  ));
});

export default TableRow;
