import React from "react";
import { Document, PDFViewer } from "@react-pdf/renderer";

const Preview = (props) => {
    const { title, page } = props;

    return <PDFViewer style={{ width: "100%", height: "100%" }} showToolbar={true}>
        <Document title={title} author={"BtpGo"} producer={"BtpGo"} creator={"BtpGo"} >
            {page}
        </Document>
    </PDFViewer>
};

export default Preview;
