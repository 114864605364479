import {
  Navbar,
  Nav,
  Container,
} from "reactstrap";

import DropDownNavbarMenu from "components/Navbars/DropDownNavbarMenu";

const AdminNavbar = (props) => {
  
  const { currentUser } = props;

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid className="justify-content-end">
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <DropDownNavbarMenu currentUser={currentUser} />
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
