const OtherInformationStep = ({ onChange, websiteData }) => {
  const mainTitle = "On y est presque !!";

  return (
    <>
      <h1>{mainTitle}</h1>
      <div class="d-flex bd-highlight">
        <div class="p-2 flex-fill bd-highlight">
          {/* <div class="form-group row">
            <label
              for="companyFuture"
              class="col-sm-4 col-form-label text-right"
            >
              Avenir de votre entreprise
            </label>
            <div class="col-sm-6">
              <textarea
                type="text"
                class="form-control"
                rows={2}
                id="companyFuture"
                name="companyFuture"
                onChange={onChange}
                value={websiteData.companyFuture}
                placeholder="Avenir de votre entreprise"
              />
            </div>
          </div> */}

          <div class="form-group row">
            <label
              for="hostNameSite"
              class="col-sm-4 col-form-label text-right"
            >
              Choisir votre nom de domaine
            </label>
            <div class="col-sm-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">https://</span>
                </div>
                <input
                  type="text"
                  class="form-control pl-2"
                  id="hostNameSite"
                  name="hostNameSite"
                  onChange={onChange}
                  value={websiteData.hostNameSite}
                  placeholder="Votre nom de domaine"
                />
                <div class="input-group-append">
                  <span class="input-group-text">.btpgo.fr</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherInformationStep;
