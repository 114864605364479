import React from "react";
import client from "../../assets/img/welcome/client-1.png";
import client1 from "../../assets/img/welcome/client-2.png";
import client2 from "../../assets/img/welcome/client-3.png";
import client3 from "../../assets/img/welcome/client-4.png";
import client4 from "../../assets/img/welcome/client-8.png";
import client5 from "../../assets/img/welcome/client-6.png";
import client6 from "../../assets/img/welcome/client-7.png";
import client7 from "../../assets/img/welcome/client-5.png";
import client8 from "../../assets/img/welcome/client-9.png";
import client9 from "../../assets/img/welcome/client-10.png";
import client10 from "../../assets/img/welcome/client-11.png";
import client11 from "../../assets/img/welcome/client-12.png";

import "../Welcome/Principal/offre/offre.css";

const data = [
  {
    image: client,
  },
  {
    image: client1,
  },
  {
    image: client2,
  },
  {
    image: client3,
  },
  {
    image: client4,
  },
  {
    image: client5,
  },
  {
    image: client6,
  },
  {
    image: client7,
  },
  {
    image: client8,
  },
  {
    image: client9,
  },
  {
    image: client10,
  },
  {
    image: client11,
  },
];

const Partenaires = () => {
  // const style = {
  //   backgroundColor: backgroundColor,
  // };
  return (
    <div className="row ">
      <div className="col-md-12 mt-5">
        <h2
          className="display-4 pb-4 d-flex justify-content-center"
          style={{
            fontWeight: "bold",
            gap: "1%",
            color: "rgb(50,104,136)",
          }}
        >
          Nos partenaires
        </h2>
      </div>
      <div className="partenaire">
        {data.map((element, k) => {
          return (
            <div
              key={k}
              className="col-6 col-sm-6 col-md-3 mb-3"
              data-aos="fade-up"
            >
              <img style={{ maxWidth: "90%" }} alt="..." src={element.image} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Partenaires;
