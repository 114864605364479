const OverviewStep = () => {
  const mainTitle = "Commencer à créer votre site web";

  const data = [
    {
      title: "Les informations de votre entreprise",
      description:
        "Ajouter le slogan, la spécialité et domaine d'expertise de l'entreprise ..",
      image: "/consturctionf_1.gif",
    },
    // {
    //   title: "Les Problèmes et solutions",
    //   description:
    //     "Décrivez 3 problèmes et 3 solutions que vous avez à ces problèmes",
    //   image: "/consturctionf_1.gif",
    // },
    {
      title: "Domaine de differentiation",
      description: "Domaine de differentiation, avantage et engagement...",
      image: "/differentiation.gif",
    },
    // {
    //   title: "Equipe de travail",
    //   description:
    //     "Domaine de differentiation, avantage, certification labelle, engagement",
    //   image: "/consturctionf_1.gif",
    // },
    {
      title: "Autres informations...",
      description:
        // "Equipe de travail, l'avenir de l'entreprise, présence sur les réseaux sociaux, page jaune..",
        "Votre nom de domaine...",
      image: "/consturctionf_1.gif",
    },
  ];

  return (
    <>
      <div class="row bd-highlight">
        <div class="col-xs-12 col-sm-12 col-md-6 p-2 align-self-center flex-fill bd-highlight">
          <h1 className="text-6xl leading-normal text-center">{mainTitle}</h1>
          <div className="m-auto bd-highlight text-center">
            <img
              src="/consturctionf_1.gif"
              className="rounded"
              width={200}
              height={160}
              alt="overview"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 p-2 flex-fill bd-highlight">
          <ul className="list-unstyled">
            {data.map(({ description, title, image }, index) => (
              <li key={title} className="d-flex mb-2  bd-highlight">
                <strong className="text-xl align-self-center bd-highlight">
                  <h3>{index + 1}</h3>
                </strong>
                <div className="pl-2   pr-2 align-self-center bd-highlight">
                  <strong className="text-xl">
                    <h3> {title}</h3>
                  </strong>
                  <p className="">{description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default OverviewStep;
