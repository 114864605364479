import React from "react";
import WelcomeNavbar from "components/Navbars/Welcome/WelcomeNavbar";
import "./introProperty.css";
import Buttons from "components/Ui/Button";
import { AiOutlineSearch } from "react-icons/ai";

const IntroProperty = () => {
  return (
    <>
      <div className="backgroundProperty">
        <WelcomeNavbar />
        <div className="introPropertyRow">
          <div className="col-md-6 d-flex flex-column  propertySize">
            {/* <div className="contact" data-aos="fade-right"> */}
            <div className="introPropertyTitle">
              Trouvez les professionnels parfaits pour vos projets de rénovation
            </div>
            <input className="inputProperty" />
            <AiOutlineSearch className="iconProperty" />

            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscription" />
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              className="imgIntroProperty"
              alt="..."
              src={require("../../../../assets/img/principal/introProperty.png")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroProperty;
