import React, { useState, useEffect } from "react";
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import { IoIosAddCircle } from "react-icons/io";
import { AiFillDelete, AiOutlineStar } from "react-icons/ai";
import { FaPen } from "react-icons/fa";
import PatrimoineEquipement from "./PatrimoineEquipement";
import "./patrimoine.css";
import image from "../../../assets/img/brand/ImageNotFound.png";
import { Link } from "react-router-dom";
import { siteService } from "utils/_services/sites.service";

const PatrimoineDisplaySite = ({ handleShow }) => {
  const [sites, setSites] = useState([]);
  const [companyId, setCompanyId] = useState(2);
  const [selectedSiteId, setSelectedSiteId] = useState(null);


  useEffect(() => {
    async function fetchSites() {
      try {
        const response = await siteService.searchSite({ companyId });
        setSites(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchSites();
  }, [companyId]);

  // const handleSiteClick = (id) => {
  //   if (selectedSiteId === id) {
  //     setSelectedSiteId(null);
  //   } else {
  //     setSelectedSiteId(id);
  //   }
  // };

  return (
    <>
      {/* <div
        style={{ color: "#2dce89", cursor: "pointer"}}
        onClick={handleShow}
      >
        Ajouter un nouveau site
      </div> */}
      <Col className="order-xl-1" xl="6" style={{ paddingRight: 0 }}>
        <div
          className="d-flex justify-content-center "
          onClick={handleShow}
          style={{ cursor: "pointer" }}
        >
          <IoIosAddCircle className="icon-p" />
          <p>Créer / modifier un bâtiment</p>
        </div>
        {sites.map((site) => {
          // const isOpen = selectedSiteId === site.id;
          return (
            <div className="d-flex ">
              <Link to="/admin/site">
                <img
                  src={image}
                  alt="avatar"
                  className="rounded img-fluid"
                  style={{
                    marginBottom: "2rem",
                    display: "block",
                    height: "140px",
                  }}
                />
              </Link>
              <div className="ml-3">
                <p>Adresse</p>
                <p>Nombre de nouvelles actions</p>
                <p>Nombre d'actions en cours</p>
              </div>
            </div>
            // <Card
            //   style={{
            //     background: "#f8f9fe",
            //     border: "none",
            //     marginBottom: "2rem",
            //     boxShadow: "0 1px 3px rgb(50 50 93 / 15%)",
            //     width:"100px"
            //   }}
            // >
            //   <CardBody
            //     style={{ padding: 0 }}
            //     key={site.id}
            //     onClick={() => handleSiteClick(site.id)}
            //   >
            //     <Form>
            //       <Card
            //         style={{
            //           cursor: "pointer",
            //           border: "none",
            //           borderRadius: 0,
            //         }}
            //       >
            //         <CardBody>
            //           <Row>
            //             <Col md="12" lg="12">
            //               <div className="d-flex justify-content-between">
            //                 <h2>
            //                   <AiOutlineStar />
            //                   {site.name}
            //                 </h2>
            //                 <p>Allarmant</p>
            //                 <p>PACA</p>
            //                 <p>Marseille</p>
            //                 <div>
            //                   <IoIosAddCircle className="icon-p" />
            //                   <AiFillDelete className="icon-p" />
            //                   <FaPen className="icon-p" />
            //                 </div>
            //               </div>
            //             </Col>
            //           </Row>
            //         </CardBody>
            //       </Card>
            //       {isOpen && <PatrimoineEquipement />}
            //     </Form>
            //   </CardBody>
            // </Card>
          );
        })}
      </Col>
    </>
  );
};

export default PatrimoineDisplaySite;
