import React from "react";
import WelcomeNavbar from "components/Navbars/Welcome/WelcomeNavbar";
import "./intro.css";
import Buttons from "components/Ui/Button";
import principalBackround from "assets/img/principal/fondPrincipal.jpeg";
import work from "assets/img/welcome/illustration_3.png";

const Intro = () => {
  return (
    <>
      <WelcomeNavbar />
      <div className="container-fluid bgDefault mt-8" id="services">
        <div
          className="row d-flex justify-content-center "
          style={{
            alignItems: "center",
            height: "auto",
          }}
        >
          <div className="col-md-6 col-xs-12 text-center justify-content-center">
            <span className="tittleLogo">BtpGo</span>
            <h1 className="titleLandingPage">
              Le facilitateur business du secteur du bâtiment 100% gratuit
            </h1>
            <div className="mb-16 mt-4 mr-2 ml-2 descriptionLandingPage">
              La 1er plateforme pour mettre en relation les acteurs de
              l'immobilier, de la construction et du btp.
            </div>
            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
          </div>

          <div className="col-md-6 col-xs-12">
            <img className="imageLandingPage" alt="work" src={work} />
          </div>
        </div>
      </div>
    </>
  );

};

export default Intro;
