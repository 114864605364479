export function formatStringDate(dateString) {
    const date = new Date(dateString);

    // Options pour formater la date en français
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    // Utiliser toLocaleDateString avec 'fr-FR' pour formater en français
    return date.toLocaleDateString('fr-FR', options);
}

export function getformatedDate() {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}