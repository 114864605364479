import React from "react";

export default function PricingCard({ pricingPlan, onChoosPlanClick }) {
  return (
    <article className="pricing-card">
      <h3>{pricingPlan.title}</h3>
      {/* <div>Essential features</div> */}
      {/* <div className="pricing-card__price--original">
        <s>
          {pricingPlan.amount}
          {pricingPlan.currencySymbol}
        </s>
      </div> */}
      <div className="pricing-card__price">
        {pricingPlan.amount}
        {pricingPlan.currencySymbol}
        <span className="period">
          / {pricingPlan.currentInterval === "month" ? "mois" : "an"}
        </span>
      </div>
      <ul>
        {pricingPlan.features.map((feature, index) => (
          <li>{feature}</li>
        ))}
      </ul>
      {pricingPlan.choosePlanText && (
        <button onClick={pricingPlan.onChoosPlanClick} className="enroll">
          {pricingPlan.choosePlanText}
        </button>
      )}

      {pricingPlan.choosePlanText === undefined && (
        <div className="price-label">Offre de base</div>
      )}
    </article>
  );
}
