import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

const AuthNavMobile = ({ closeCollapse }) => {
  return (
    <>
      <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
        <div
          className="d-flex justify-content-end display-4 d-md-none "
          onClick={closeCollapse}
        >
          <i
            className="ni ni-fat-remove"
            style={{
              background: "#9077ed",
              color: "white",
              borderRadius: 2,
            }}
          ></i>
        </div>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink
              className="nav-link-icon"
              to={process.env.REACT_APP_WELCOME_PAGE_PATH}
              tag={Link}
              onClick={closeCollapse}
            >
              <i className="ni ni-key-25" style={{ color: "#9077ed" }} />
              <span
                className="nav-link-inner--text"
                style={{ color: "#9077ed" }}
              >
                Connectez vous c'est gratuit
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </UncontrolledCollapse>
    </>
  );
};

export default AuthNavMobile;
