import { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardBody, Form, Row, Col } from "reactstrap";
import {
  RiFacebookCircleFill,
  RiLinkedinBoxFill,
  RiLinksFill,
  RiInstagramFill,
} from "react-icons/ri";
import { NotificationManager } from "react-notifications";

import { companyService } from "utils/_services/company.service";

import GenerateFormInput from "components/Ui/GenerateFormInput";

const EditCompanyCommunication = ({ companyData, userId }) => {
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (companyData?.id) {
      companyService
        .getCompanyById(companyData.id)
        .then((res) => {
          setCompany(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [companyData]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (company?.siretNumber === undefined || company?.siretNumber === "") {
      NotificationManager.error("Le numéro de siret est obligatoire");
      return;
    }
    if (company?.id && company.id > 0) {
      companyService
        .updateCompany(company)
        .then((res) => {
          NotificationManager.success(
            "Votre entreprise a été mis a jour avec succes"
          );
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    } else {
      companyService
        .addCompany(company, userId)
        .then((res) => {
          const data = res.data;
          if (data.resultState === 0) {
            NotificationManager.error(data.message);
          } else {
            NotificationManager.success(
              "Votre entreprise a été mis a jour avec succes"
            );
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    }
  };

  const onChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Card className="bg-secondary shadow">
      <CardBody>
        <div className="pl-lg-4">
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="6" sm="6">
                <GenerateFormInput
                  forInput="input-siret"
                  label="Lien site web"
                  editModeClassName={true}
                  value={company?.websiteLink}
                  onChange={onChange}
                  name="websiteLink"
                  placeholder="..."
                  type="text"
                  icon={
                    <RiLinksFill
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  disabled={false}
                />
              </Col>
              <Col md="6" sm="6">
                <GenerateFormInput
                  forInput="input-siret"
                  label="Lien Facebook"
                  editModeClassName={true}
                  value={company?.facebookLink}
                  onChange={onChange}
                  name="facebookLink"
                  placeholder="..."
                  type="text"
                  icon={
                    <RiFacebookCircleFill
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  disabled={false}
                />
              </Col>
              <Col md="6" sm="6">
                <GenerateFormInput
                  forInput="input-siret"
                  label="Lien Linkedin"
                  editModeClassName={true}
                  value={company?.linkedinLink}
                  onChange={onChange}
                  name="linkedinLink"
                  placeholder="..."
                  type="text"
                  icon={
                    <RiLinkedinBoxFill
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  disabled={false}
                />
              </Col>
              <Col md="6" sm="6">
                <GenerateFormInput
                  forInput="input-siret"
                  label="Lien Instagram"
                  editModeClassName={true}
                  value={company?.instagramLink}
                  onChange={onChange}
                  name="instagramLink"
                  placeholder="..."
                  type="text"
                  icon={
                    <RiInstagramFill
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  disabled={false}
                />
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-end">
            <Button
              className="my-3 btn btn-success"
              title="Sauvegarder"
              onClick={onSubmit}
            >
              Sauvegarder
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default EditCompanyCommunication;
