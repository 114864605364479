import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { cityService } from "utils/_services/city.service";
import "./search.css";

const SearchCity = ({
  selectedCityValue,
  changeSelectdCityValue,
  disabledCompany,
}) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  useEffect(() => {
    if (selectedCityValue > 0) {
      cityService.getCityById(selectedCityValue).then((response) => {
        const selectedCityFromResponse = {
          id: response.data.id,
          label: `${response.data.cityName} (${response.data.zipCode})`,
        };
        setCities([selectedCityFromResponse]);
        setSelectedCity(selectedCityFromResponse);
      });
    }
  }, []);

  const handleSelectCityChange = (city) => {
    setSelectedCity(city);
    changeSelectdCityValue(city);
  };

  const handleOnChangeCity = (event) => {
    const zipCodeOrCityName = event.target.value;

    if (zipCodeOrCityName.length > 0) {
      const filter = {
        zipCodeOrCityName: `${zipCodeOrCityName}`,
      };

      cityService.searchCities(filter).then((resp) => {
        setCities(
          resp.data.map((city) => {
            return {
              id: city.id,
              label: `${city.cityName} (${city.zipCode})`,
            };
          })
        );
      });
    } else {
      setCities([]);
    }
  };

  return (
    <Autocomplete
      name="city"
      // disableClearable
      // freeSolo
      disabled={disabledCompany}
      noOptionsText={"Ville ou code postale"}
      options={cities}
      getOptionLabel={(option) => option?.label || ""}
      value={selectedCity || null}
      renderInput={(params) => (
        <TextField
          style={{
            background: "white",
            borderRadius: "0.375rem",
            // color: "black",
            // fontWeight: "600",
          }}
          variant="outlined"
          disabled={disabledCompany}
          {...params}
          onChange={handleOnChangeCity}
          placeholder="Ville ou code postale"
        />
      )}
      onChange={(event, value) => handleSelectCityChange(value)}
    />
  );
};

export default SearchCity;
