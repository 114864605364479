import React from "react";
import { Card, CardBody } from "reactstrap";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { MdOutlineBatteryChargingFull } from "react-icons/md";

const DroppableArea = ({ droppableId, tasks }) => {
  return (
    <>
      <h2>{droppableId}</h2>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="mb-3"
          >
            <Card style={{ background: "#DCDCDC" }} className="shadow">
              {/* <CardHeader> */}
              {/* </CardHeader> */}
              <CardBody>
                {tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className="bg-secondary mb-3"
                          style={{
                            padding: "1vh",
                            width: "182px",
                            height: "115px",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            minWidth: 0,
                            wordWrap: "break-word",
                            backgroundClip: "border-box",
                            borderLeft: "5px solid #ffd600",
                            borderRadius: "0.375rem",
                          }}
                        >
                          <div className="d-flex">
                            <MdOutlineBatteryChargingFull className="text-yellow" />

                            <h5>{task.content}</h5>
                          </div>
                          <div className="d-flex">
                            <p>Bilel</p>
                          </div>
                          <div className="d-flex justify-content-between ">
                            <p>Etat: </p>
                            <p>{task.status}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </CardBody>
            </Card>
          </div>
        )}
      </Droppable>
    </>
  );
};

export default DroppableArea;
