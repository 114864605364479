import * as React from "react";

import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Col, Row } from "reactstrap";

export default function CheckboxesGroup({
  selectedItems,
  defaultItems,
  title,
  onChange,
}) {
  return (
    <>
      {selectedItems && (
        <Box sx={{ display: "flex" }}>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <h4>{title}</h4>
            <FormGroup>
              <Row>
                {defaultItems.map((item) => {
                  return (
                    <Col sm="6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedItems.filter((e) => e.id === item.id)
                                .length > 0
                            }
                            onChange={onChange}
                            name={item.id}
                            color="secondary"
                          />
                        }
                        label={item.title}
                      />
                    </Col>
                  );
                })}
              </Row>
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </>
  );
}
