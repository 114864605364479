import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: 12,
    textDecoration: "underline",
  },
  text: {
    fontSize: 10,
    marginBottom: 2,
    fontStyle: "italic",
  },
});

const InvoiceBankDetails = ({ item }) => {

  return (
    <View>
      <Text style={styles.title}>{"Détails bancaires"}</Text>
      <Text style={styles.text}>{`Banque: ${item.bankName ?? ""}`}</Text>
      <Text style={styles.text}>{`IBAN: ${item.bankReference ?? ""}`}</Text>
    </View>
  );
}
export default InvoiceBankDetails;
