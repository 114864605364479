import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Card,
  Grid,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Container,
} from "@material-ui/core";
import GenericHeader from "components/Headers/GenericHeader";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "components/Ui/Button";
import {
  getUserStatus,
  getCurrentUser,
  fetchUser,
} from "features/slices/userSlice";
import "./style.css";

import { loadStripe } from "@stripe/stripe-js";

import { paymentService } from "utils/_services/payment.service";
import PricingCard from "components/Payment/pricingCard";

export default function Subscription() {
  const [stripePromise, setStripePromise] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [prices, setPrices] = useState(null);
  const [currentInterval, setCurrentInterval] = useState("month");

  const user = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();

  const pricingPlans = [
    {
      title: "Gratuit",
      amount: 0,
      currencySymbol: "€",
      features: [
        "1 profil perso-pro",
        "Vérification siret (A venir)",
        "1 recherche annuaire pro /jour",
        "Feed publication écrit",
        "Visibilité sur l'annuaire des pros activée",
        "Page auto genéré unique (Logo fixe)",
        "Logo fixe",
        "Score visible (A venir)",
      ],
      // choosePlanText: "Choisir base",
      // onChoosPlanClick: () =>
      //   paySubscriptionClick(
      //     prices
      //       .filter((x) => x.active && x.interval === currentInterval)
      //       .find((i) => i.product.name === "Gratuit").id
      //   ),
      currentInterval,
    },
    {
      title: "Pro",
      amount: 49,
      currencySymbol: "€",
      features: [
        "1 profil entreprise lighnt (vérification siret, état...)",
        "2 profil personnels",
        "5 recherche annuaire pro /jour",
        "Feed publication écrit et vidéo",
        "Visibilité sur l'annuaire des pros activée",
        "Page auto genéré unique (Logo modifiable)",
        "Score visible (A venir)",
        "3 numéros de contacts possibles",
        "3 mails possibles",
      ],
      choosePlanText: "Choisir Pro",
      onChoosPlanClick: () =>
        paySubscriptionClick(
          prices
            .filter((x) => x.active && x.interval === currentInterval)
            .find((i) => i.product.name === "Pro").id
        ),
      currentInterval,
    },
    {
      title: "Entreprise",
      amount: 79,
      currencySymbol: "€",
      features: [
        "5 profil entreprise (vérification siret, état...",
        "15 profil personnels",
        "Recherche annuaire pro /jour illimité",
        "Feed publication écrit et vidéo",
        "Visibilité sur l'annuaire des pros activée",
        "Page auto genéré (équipe, logo modifiable)",
        "Bandeau profil et recherche personnalisable",
        "Score entreprise additionné de tous les profils",
        "Score de l'antenne",
        "Score personnel du profil",
      ],
      choosePlanText: "Choisir Entreprise",
      onChoosPlanClick: () =>
        paySubscriptionClick(
          prices
            .filter((x) => x.active && x.interval === currentInterval)
            .find((i) => i.product.name === "Entreprise").id
        ),
      currentInterval,
    },
  ];

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser());
    }
  }, [userStatus, dispatch]);

  const paySubscriptionClick = (priceId) => {
    paymentService
      .createCheckoutSession(priceId, user.id, user.mail)
      .then((response) => {
        var sessionId = response.data.sessionId;
        stripePromise.then(async (stripe) => {
          stripe?.redirectToCheckout({ sessionId });
        });
      });
  };

  const goToBillingPortal = () => {
    paymentService.getUserPortalLink().then((response) => {
      var url = response.data;
      window.location.replace(url);
    });
  };

  useEffect(() => {
    paymentService.paymentConfig().then((response) => {
      setStripePromise(loadStripe(response.data.publishableKey));
    });

    paymentService.getUserSubscription().then((response) => {
      setSubscription(response.data);
    });

    paymentService.getActivesPrices().then((response) => {
      setPrices(response.data);
    });
  }, []);
  return (
    <>
      <GenericHeader />
      {stripePromise && (
        <>
          <div class="pricing-container">
            {prices &&
              pricingPlans.map((pricingPlan) => (
                <PricingCard
                  pricingPlan={pricingPlan}
                  paySubscriptionClick={() =>
                    paySubscriptionClick(
                      prices
                        .filter(
                          (x) => x.active && x.interval === currentInterval
                        )
                        .find((i) => i.product.name === pricingPlan.title).id
                    )
                  }
                />
              ))}
          </div>
          {subscription && (
            <>
              <div className="d-flex flex-wrap justify-content-center w-100 mt-4 p-3">
                <h3 className="col-xs-12 my-auto mr-md-3">
                  Votre plan actuel est {subscription.price.product.name}
                </h3>
                {/* <button onClick={goToBillingPortal} className="enroll">
                  Gérer mon abonnement
                </button> */}
                <Buttons
                  onClick={goToBillingPortal}
                  buttonText="Gérer mon abonnement"
                  buttonClass="col-xs-12"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

/*
        <PricingCard pricing={} />
        <article class="pricing-card">
          <h3>Essential</h3>
          <div>Essential features</div>
          <div class="pricing-card__price--original">
            <s>$59.99</s>
          </div>
          <div class="pricing-card__price">$54.99</div>
          <div class="period">/ month</div>
          <ul>
            <li>Basic access to content and resources.</li>
            <li>Email support with a 48-hour response time.</li>
            <li>Access to community forums for peer-to-peer support.</li>
            <li>Monthly newsletter with updates and tips.</li>
            <li>Limited access to standard tools and features.</li>
          </ul>
          <a class="enroll" href="#">
            Choose Basic
          </a>
        </article>
        <article class="pricing-card">
          <h3>Advanced</h3>
          <div>Advanced features</div>
          <div class="pricing-card__price--original">
            <s>$112.00</s>
          </div>{
          <div class="pricing-card__price">$89.99</div>
          <div class="period">/ month</div>
          <ul>
            <li>All features of the Essential Plan.</li>
            <li>Priority email support with a 24-hour response time.</li>
            <li>Access to exclusive webinars and online events.</li>
            <li>Enhanced tools and features, including analytics.</li>
            <li>
              Customizable content experience based on user preferences.
              asfsfaafsfsafsafsafsaafsfsafsa
            </li>
          </ul>
          <a class="enroll" href="#">
            Choose Advanced
          </a>
        </article>

        <article class="pricing-card pricing-card--primary">
          <div class="pricing-card__banner">Most popular</div>
          <h3>Pro</h3>
          <div>Advanced features</div>
          <div class="pricing-card__price--original">
            <s>$125.00</s>
          </div>
          <div class="pricing-card__price">$94.99</div>
          <div class="period">/ month</div>
          <ul>
            <li>All features of the Advanced Plan.</li>
            <li>Dedicated account manager for personalized assistance.</li>
            <li>Early access to new features and beta programs.</li>
            <li>Advanced analytics and detailed reports.</li>
            <li>High-priority customer service with instant chat support.</li>
          </ul>
          <a class="enroll" href="#">
            Choose Pro
          </a>
        </article>
      </div>*/
//     </> }
//   );
// }
