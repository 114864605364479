import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { cgvData } from "../../components/Droits/CgvData";
import img from "../../assets/img/droits/cache.png";
import "./droits.css"

const Cgv = () => {
  return (
    <>
      <Container>
        <h1 className="d-flex justify-content-center">
          Conditions général de ventes
        </h1>
        <Row className="justify-content-center">
          {cgvData.map((item, index) => (
            <Col lg="12" key={index}>
              <Card style={{ border: "none" }}>
                <CardBody>
                  <CardTitle>{item.titre}</CardTitle>
                  <CardText>{item.text}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <div lg="12" style={{ flexDirection: "column", padding: "1.5rem" }}>
          <p>Fait à Ajaccio, le 01/02/2023</p>
          <img className="imgCache" src={img} alt=".." />
        </div>
      </Container>
    </>
  );
};

export default Cgv;
