import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { cell, headerStyle, defaultBackgroundColor } from "./styles";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: cell.height,
    fontStyle: "bold",
    flexGrow: 1,
    borderColor: cell.borderColor,
    borderWidth: cell.borderWidth,
    backgroundColor: defaultBackgroundColor.headers,
  },

  column: {
    borderRightColor: cell.borderColor,
    borderRightWidth: cell.borderWidth,
  },
});

const TableHeader = ({ headers, customCss }) => {
  const lastIndex = headers.length - 1;
  const getCellStyles = (index, size) => {
    return index !== lastIndex
      ? [{ ...headerStyle, ...customCss.text }, { width: size }, styles.column]
      : [{ ...headerStyle, ...customCss.text }, { width: size }];
  };
  return (
    <View style={{ ...styles.container, ...customCss.view }}>
      {headers.map((item, index) => {
        return (
          <Text style={getCellStyles(index, item.size)}>{item.title}</Text>
        );
      })}
    </View>
  );
};

export default TableHeader;
