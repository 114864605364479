import { Nav, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../Ui/fond.css";

const Footer = () => {
  return (
    <>
      <footer className="py-2 bgDefault">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}
                <span
                  className="font-weight-bold ml-1"
                  style={{ color: "#107aca" }}
                >
                  BtpGO
                </span>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                {/* <Link
                  className="m-3"
                  href="https://www.creative-tim.com?ref=adr-auth-footer"
                >
                  BtpGO
                </Link> */}

                <Link className="m-3" to="/blog">
                  Blog
                </Link>

                <Link className="m-3" to="/auth/condition-generales-de-vente">
                  CGV
                </Link>

                <Link
                  className="m-3"
                  to="/auth/condition-generales-d-utilisation"
                >
                  CGU
                </Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
