import React from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from "components/Headers/HeaderGmao";
// import { GiAbstract002 } from "react-icons/gi";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { AiFillFolder, AiFillDelete } from "react-icons/ai";
import { MdUpdate } from "react-icons/md";
import { FaFileInvoice, FaFileContract } from "react-icons/fa";
import { IoMdPhotos } from "react-icons/io";

const Ged = () => {
  return (
    <>
      <Header />
      <Container className="mt-5" fluid>
        {/* <div className="mb-5"> */}
        <div className="d-flex" style={{ marginLeft: "1rem", margin: "2rem" }}>
          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
            <i className="ni ni-settings" />
          </div>
          <h1 style={{ marginLeft: "1rem" }}>Mes outils</h1>
        </div>
        {/* </div> */}
        <Row>
          <Col className=" mb-5 mb-xl-0" xl="3">
            <Card
              className="card-profile shadow"
              style={{ display: "flex", alignItems: "center", height: "58rem" }}
            >
              <CardBody className=" pt-md-4" style={{ textAlign: "justify" }}>
                <FormGroup>
                  <MdUpdate style={{ marginRight: "1rem" }} />
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Updates
                  </label>
                </FormGroup>
                <FormGroup>
                  <FaFileInvoice style={{ marginRight: "1rem" }} />
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Devis
                  </label>
                </FormGroup>
                <FormGroup>
                  <FaFileInvoice style={{ marginRight: "1rem" }} />

                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Factures
                  </label>
                </FormGroup>
                <FormGroup>
                  <i
                    style={{ marginRight: "1rem" }}
                    className="ni ni-settings"
                  />
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Rapport d'intervention
                  </label>
                </FormGroup>
                <FormGroup>
                  <FaFileContract style={{ marginRight: "1rem" }} />
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Contrat
                  </label>
                </FormGroup>
                <FormGroup>
                  <IoMdPhotos style={{ marginRight: "1rem" }} />
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Photos
                  </label>
                </FormGroup>
                <FormGroup>
                  <AiFillDelete style={{ marginRight: "1rem" }} />
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Deleted
                  </label>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="9" style={{ paddingRight: 0 }}>
            <Card style={{ background: "#f8f9fe", border: "none" }}>
              <CardBody style={{ padding: 0 }}>
                <div className="d-flex justify-content-start">
                  <p className="mr-4">GED</p>
                  <p className="mr-4">Mes contrats</p>
                  <p className="mr-4">Fluides</p>
                  <p className="mr-4">Suivi Financier</p>
                </div>
                <Form>
                  <h2>Directory</h2>

                  <hr style={{ marginTop: 0, marginBottom: 0 }} />
                  <div className="d-flex ">
                    <p className="mr-2" style={{ fontSize: "3rem" }}>
                      <AiFillFolder />
                    </p>
                    <p className="pt-4">Energie</p>
                  </div>

                  <p
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      marginLeft: "3.5rem",
                    }}
                  >
                    12 Mb
                  </p>
                  <hr style={{ marginTop: 0, marginBottom: 0 }} />

                  <div className="d-flex ">
                    <p className="mr-2" style={{ fontSize: "3rem" }}>
                      <AiFillFolder />
                    </p>
                    <p className="pt-4">Conditionnement d'air</p>
                  </div>

                  <p
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      marginLeft: "3.5rem",
                    }}
                  >
                    12 Mb
                  </p>
                  <hr style={{ marginTop: 0, marginBottom: 0 }} />

                  <div className="d-flex ">
                    <p className="mr-2" style={{ fontSize: "3rem" }}>
                      <AiFillFolder />
                    </p>
                    <p className="pt-4">Protection</p>
                  </div>

                  <p
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      marginLeft: "3.5rem",
                    }}
                  >
                    12 Mb
                  </p>
                  <hr style={{ marginTop: 0, marginBottom: 0 }} />

                  <div className="d-flex ">
                    <p className="mr-2" style={{ fontSize: "3rem" }}>
                      <AiFillFolder />
                    </p>
                    <p className="pt-4">Sécurité</p>
                  </div>

                  <p
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      marginLeft: "3.5rem",
                    }}
                  >
                    12 Mb
                  </p>
                  <hr style={{ marginTop: 0, marginBottom: 0 }} />

                  <div className="d-flex ">
                    <p className="mr-2" style={{ fontSize: "3rem" }}>
                      <AiFillFolder />
                    </p>
                    <p className="pt-4">Levage</p>
                  </div>

                  <p
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      marginLeft: "3.5rem",
                    }}
                  >
                    12 Mb
                  </p>
                  <hr style={{ marginTop: 0, marginBottom: 0 }} />

                  <div className="d-flex ">
                    <p className="mr-2" style={{ fontSize: "3rem" }}>
                      <AiFillFolder />
                    </p>
                    <p className="pt-4">Sanitaire</p>
                  </div>

                  <p
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      marginLeft: "3.5rem",
                    }}
                  >
                    12 Mb
                  </p>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <NotificationContainer />
        </Row>
      </Container>
    </>
  );
};

export default Ged;
