import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import Header from "components/Headers/HeaderGmao";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import PatrimoineSideBar from "components/Gmao/Patrimoine/PatrimoineSideBar";
import PatrimoineDisplaySite from "components/Gmao/Patrimoine/PatrimoineDisplaySite";
import PatrimoineModal from "components/Gmao/Patrimoine/PatrimoineModal";

const Patrimoine = () => {
  const [toggleState, setToggleState] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />
      <Container className="mt-5" fluid style={{ flexGrow: 1 }}>
        {/* <div className="mb-5"> */}
        <div className="d-flex justify-content-between">
          <div className="d-flex" style={{ margin: "2rem" }}>
            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
              <i className="ni ni-building" aria-hidden="true"></i>
            </div>
            <h1 style={{ marginLeft: "1rem" }}>Mon patrimoine</h1>
          </div>
        </div>

        <PatrimoineModal show={show} handleClose={handleClose} />

        <Row>
          <PatrimoineSideBar />
          <PatrimoineDisplaySite show={show} handleShow={handleShow}/>

          <NotificationContainer />
        </Row>
      </Container>
    </>
  );
};

export default Patrimoine;
