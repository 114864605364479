import TextareaWithLimit from "components/Ui/TextareaWithLimit";
const ProfessionalsDataStep = ({ onChange, websiteData }) => {
  const mainTitle = "Quelles sont les informations de votre entreprise ?";

  return (
    <>
      <h1>{mainTitle}</h1>
      <div class="d-flex bd-highlight">
        <div class="p-2 flex-fill bd-highlight">
          <div class="form-group row">
            <label for="companyName" class="col-sm-4 col-form-label text-right">
              Le nom de votre entreprise
            </label>
            <div class="col-sm-6">
              <TextareaWithLimit
                type="text"
                class="form-control"
                id="companyName"
                name="companyName"
                maxlength={100}
                rowsCount={1}
                onChange={onChange}
                value={websiteData.companyName}
                placeholder="Nom de votre entreprise"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputSlogan" class="col-sm-4 col-form-label text-right">
              Votre slogan
            </label>
            <div class="col-sm-6">
              <TextareaWithLimit
                type="text"
                class="form-control"
                id="slogan"
                name="slogan"
                maxlength={100}
                rowsCount={1}
                onChange={onChange}
                value={websiteData.slogan}
                placeholder="Votre slogan "
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="inputSpeciality"
              class="col-sm-4 col-form-label text-right"
            >
              Spécialité et domaine d'expertise
            </label>
            <div class="col-sm-6">
              <TextareaWithLimit
                id="speciality"
                name="speciality"
                maxlength={300}
                value={websiteData.speciality}
                onChange={onChange}
                rowsCount={4}
                placeholder="Spécialité et domaine d'expertise"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalsDataStep;
