import Profile from "views/profile/profile";
import Subscription from "views/subscription/Subscription";
import MainStepper from "components/Stepper/MainStepper";
import Search from "views/Search/Search.js";
import Blog from "layouts/Blog";
import Social from "views/social/Social";
import Travaux from "views/gmao/Travaux";
import Patrimoine from "views/gmao/Patrimoine/Patrimoine";
import Ged from "views/gmao/Ged";
import Login from "views/login/Login.js";
import Cgv from "views/droits/Cgv";
import Cgu from "views/droits/Cgu";
import PatrimoineDetails from "views/gmao/Patrimoine/PatrimoineDetails";
import AddReviewPage from 'views/Review/AddReviewPage';
import Main from "views/Billing/Main";

var routes = [
  {
    path: "/welcomeProperty",
    layout: "/welcomeProperty",
  },
  {
    path: "/welcomeArtisans",
    layout: "/welcomeArtisans",
  },
  {
    path: "/",
    layout: "/welcome",
  },
  {
    path: "/blog",
    layout: "/blog",
  },
  {
    path: "/:userData",
    component: Profile,
    layout: "/ap",
    showInMenu: false,
  },
  {
    path: "/user-profile",
    name: "Mon profil",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/ap",
    showInMenu: true,
  },
  {
    path: "/addReview/:companyId",
    component: AddReviewPage,
    layout: "/admin",
    showInMenu: false,
  },
  {
    path: "/create-site",
    name: "Creation de site",
    icon: "ni ni-settings text-red",
    component: MainStepper,
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/search",
    name: "A-search",
    icon: "fa fa-search text-orange",
    component: Search,
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/social",
    name: "A-network",
    icon: "ni ni-world-2 text-green",
    component: Social,
    layout: "/admin",
  },
  {
    path: "/subscription",
    name: "Abonnement",
    icon: "ni ni-money-coins text-yellow",
    component: Subscription,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Facturation",
    icon: "ni ni-money-coins text-yellow",
    component: Main,
    layout: "/admin",
  },
  {
    path: "/blogHelp",
    name: "Blog",
    icon: "ni ni-ui-04 text-info",
    component: Blog,
    layout: "/admin",
  },
  {
    path: "/demande-travaux",
    component: Travaux,
    layout: "/admin",
  },
  {
    path: "/patrimoine",
    component: Patrimoine,
    layout: "/admin",
  },
  {
    path: "/site",
    component: PatrimoineDetails,
    layout: "/admin",
  },
  {
    path: "/ged",
    component: Ged,
    layout: "/admin",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/condition-generales-de-vente",
    component: Cgv,
    layout: "/auth",
  },
  {
    path: "/condition-generales-d-utilisation",
    component: Cgu,
    layout: "/auth",
  },
];
export default routes;
