import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import { jobService } from "utils/_services/job.services";
import "./search.css";
import "../Profile/profil.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getJobsStatus,
  getAllJobs,
  fetchJobs,
} from "features/slices/jobsSlice";


const filter = createFilterOptions();

const SearchJob = ({
  selectedJobValue,
  changeSelectdJobValue,
  disabledCompany,
}) => {
  const [selectedJob, setSelectedJob] = useState({});
  const [jobs, setJobs] = useState([]);

  const jobsFromDb = useSelector(getAllJobs);
  const jobsStatus = useSelector(getJobsStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (jobsStatus === "idle") {
      dispatch(fetchJobs());
    }
  }, [jobsStatus, dispatch]);

  useEffect(() => {
    if (jobsStatus === "succeeded") {
      setJobs([...jobsFromDb]);
      setSelectedJob(jobsFromDb.find((obj) => obj.value === selectedJobValue));
    }
  }, [jobsStatus, jobsFromDb]);


  const handleSelectJobChange = (job) => {
    if (jobs.find((obj) => obj.label === job.label) === undefined) {
      setJobs([...jobsFromDb, job]);
    }
    setSelectedJob(job);
    changeSelectdJobValue(job);
  };

  return (
    <>
      {jobsStatus === "succeeded" && (
        <>
          {/* <FreeSoloCreateOption /> */}
          <Autocomplete
            style={{ padding: "inherit", color: "#8898aa" }}
            disabled={disabledCompany}
            options={jobs}
            noOptionsText={"Choisir un métier"}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              // if (option.value) {
              //   return option.inputValue;
              // }
              // Regular option
              return option.label;
            }}
            // getOptionLabel={(option) => option.label || ""}
            value={selectedJob || null}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.label);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  value: "-1",
                  label: inputValue,
                });
              }

              return filtered;
            }}

            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderInput={(params) => (
              <TextField
                style={{
                  background: "white",
                  borderRadius: "0.375rem",
                  // color: "black",
                  // fontWeight: "600",
                }}
                fullWidth
                required
                variant="outlined"
                placeholder="Choisir un métier"
                // disabled={disabledCompany}
                {...params}
              />
            )}
            onChange={(event, value) => handleSelectJobChange(value)}
          />
        </>
      )}
    </>
  );
};

export default SearchJob;
