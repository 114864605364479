import React, { useEffect, useState } from 'react';
import ReviewList from 'components/Review/ReviewList';
import { reviewsService } from 'utils/_services/review.service';

function CompanyReviews(props) {
  const { companyId } = props;
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    reviewsService
      .getReviewssByUserId(companyId)
      .then((response) => setReviews(response.data))
      .catch((err) => console.log(err));
  }, [companyId]);

  return (
    <>
      {reviews && <ReviewList reviews={reviews} />}
    </>
  );
}

export default CompanyReviews;
