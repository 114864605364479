import React from "react";
import Buttons from "components/Ui/Button";
import "../../../Ui/fond.css"

const Portfolio = () => {
  return (
    <div
      className="container-fluid bgDefault"
      id="contact"
    >
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div className="row" style={{ marginRight: "0" }}>
          <div className="col-md-6 ">
            <img
              className="imgContactProperty"
              alt="..."
              src={require("../../../../assets/img/principal/buildingArtisans.png")}
            />
          </div>
          <div
            className="contact "
            data-aos="fade-right"
            style={{ textAlign: "center" }}
          >
            <div className="contact-title" style={{ color: "rgb(50,104,136)" }}>
              C'est le temps de construire votre Portfolio Digital
            </div>
            <div className="contact-title" style={{ color: "rgb(50,104,136)" }}>
              Plus de visibilité. Plus de projets.
            </div>

            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />

          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
