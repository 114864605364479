import React, { useCallback, useMemo, useRef, useEffect } from "react";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";

export const toKeyWord = value => `{${value}}`;

const TextEditor = (props) => {
    const { field, value, handleChange, dynamicContent = { title: "", suggestions: {} } } = props;
    const quill = useRef(null);

    useEffect(() => {
        if (dynamicContent.title && Object.keys(dynamicContent.suggestions).length > 0) {
            const placeholderPickerItems = document.querySelectorAll('.ql-placeholder .ql-picker-item');
            placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
            const placeholderPickerLabel = document.querySelector('.ql-placeholder .ql-picker-label');
            placeholderPickerLabel.innerHTML = dynamicContent.title;
        }
    }, [dynamicContent]);

    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();
                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
            };
            reader.readAsDataURL(file);
        };
    }, []);

    const modules = useMemo(
        () => {
            const toolbar = {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                    placeholder: function (value) {
                        if (value) {
                            const cursorPosition = this.quill.getSelection().index;
                            const suggestion = toKeyWord(dynamicContent.suggestions[value]);
                            this.quill.insertText(cursorPosition, suggestion);
                            this.quill.setSelection(cursorPosition + suggestion.length);
                        }
                    }
                },
            }

            if (dynamicContent.title && Object.keys(dynamicContent.suggestions).length > 0)
                toolbar.container.push([{ 'placeholder': Object.keys(dynamicContent.suggestions) }])

            return {
                toolbar,
                clipboard: {
                    matchVisual: true,
                },
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [imageHandler]
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];

    return (
        <div style={{ display: "flex" }}>
            <QuillEditor
                ref={quill}
                theme="snow"
                value={value}
                formats={formats}
                modules={modules}
                onChange={(val) => handleChange(field, val)}
                style={{ width: "-webkit-fill-available" }}
            />

            {/* {enableCopy && <Clipboard value={
                <div>
                    {parse(value)}
                </div>} />} */}
        </div>
    );
};

export default TextEditor;
