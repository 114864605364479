import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "components/Headers/HeaderGmao";
import { DragDropContext } from "react-beautiful-dnd";
import DroppableArea from "components/Gmao/Patrimoine/DroppableArea";
import { IoIosAddCircle } from "react-icons/io";
// import "./patrimoine.css";
import image from "../../../assets/img/brand/ImageNotFound.png";

const PatrimoineDetails = () => {
  const [tasks, setTasks] = useState([
    {
      id: "task-1",
      content: "Task 1",
      status: "todo",
    },
    {
      id: "task-2",
      content: "Task 2",
      status: "todo",
    },
    {
      id: "task-3",
      content: "Task 3",
      status: "todo",
    },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceTasks = getTasksByStatus(result.source.droppableId);
    const destinationTasks = getTasksByStatus(result.destination.droppableId);

    const newSourceTasks = Array.from(sourceTasks);
    const newDestinationTasks = Array.from(destinationTasks);

    const [removed] = newSourceTasks.splice(result.source.index, 1);

    newDestinationTasks.splice(result.destination.index, 0, removed);

    setTasks(
      tasks.map((task) => {
        if (task.id === removed.id) {
          return {
            ...task,
            status: result.destination.droppableId,
          };
        }
        return task;
      })
    );
  };

  const getTasksByStatus = (status) => {
    return tasks.filter((task) => task.status === status);
  };

  return (
    <>
      <Header />
      <Container className="mt-5" fluid style={{ flexGrow: 1 }}>
        <Col className="order-xl-1" xl="6" style={{ paddingRight: 0 }}>
          <div className="d-flex ">
            <img
              src={image}
              alt="avatar"
              className="rounded img-fluid"
              style={{
                marginBottom: "2rem",
                display: "block",
                height: "140px",
              }}
            />
            <div className="ml-3">
              <p>Adresse</p>
              <p>Nombre de nouvelles actions</p>
              <p>Nombre d'actions en cours</p>
            </div>
          </div>
          <div
            className="d-flex mt-5  justify-content-start "
            style={{ cursor: "pointer" }}
          >
            <IoIosAddCircle className="icon-p" />
            <p>Créer un ticket</p>
          </div>
        </Col>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col md="4">
              <DroppableArea
                droppableId="todo"
                tasks={getTasksByStatus("todo")}
              />
            </Col>
            <Col md="4">
              <DroppableArea
                droppableId="in-progress"
                tasks={getTasksByStatus("in-progress")}
              />
            </Col>
            <Col md="4">
              <DroppableArea
                droppableId="done"
                tasks={getTasksByStatus("done")}
              />
            </Col>
          </Row>
        </DragDropContext>
      </Container>
    </>
  );
};

export default PatrimoineDetails;
