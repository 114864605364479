import GenericHeader from "components/Headers/GenericHeader";
export default function Canceled() {
  return (
    <>
      <GenericHeader />
      <div className="flex items-center ">
        <div className="d-flex justify-content-center text-xl item-center p-9 font-black">
          {" "}      
          <h1>L'opération a été annulée.</h1>
        </div>
      </div>
    </>
  );
}
