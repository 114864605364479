import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardImg,
  CardFooter,
  Container,
} from "reactstrap";
import CreatePost from "./CreatePost";
import { PostActions } from "./PostActions";
import GenericHeader from "components/Headers/GenericHeader";
import { socialService } from "utils/_services/social.services";
import DeletePostButton from "./DeletePostButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserStatus,
  getCurrentUser,
  fetchUser,
} from "features/slices/userSlice";
import UserAvatarInformation from "components/User/UserAvatarInformation";

export default function Social() {
  const [allPosts, setAllPosts] = useState([]);
  useEffect(() => {
    socialService
      .getAllPosts()
      .then((response) => setAllPosts(response.data))
      .catch((err) => console.log(err));
  }, []);

  const user = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser());
    }
  }, [userStatus, dispatch]);

  const addNewPost = (post) => setAllPosts([post, ...allPosts]);

  const deletePostFromArray = (postId) =>
    setAllPosts(allPosts.filter((a) => a.id !== postId));

  return (
    <>
      <GenericHeader />
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <CreatePost addNewPost={addNewPost} />
            {allPosts ? (
              <>
                {allPosts.map((postPub) => (
                  <Card id={postPub.id} className="mt-2">
                    <CardBody>
                      <UserAvatarInformation
                        firstName={postPub.createdUser.firstName}
                        lastName={postPub.createdUser.lastName}
                        jobTitle={postPub.createdUser.job?.title}
                        companyName={postPub.createdUser.company?.name}
                        photoUrl={postPub.createdUser.photo?.url}
                      />
                      {user && user.id === postPub.createdUser.id && (
                        <DeletePostButton
                          className="rigth-top-icon"
                          postId={postPub.id}
                          deletePostFromArray={deletePostFromArray}
                        />
                      )}
                    </CardBody>
                    <CardImg src={postPub.attachment?.url} top />
                    <CardBody>
                      <div className="">
                        <span>
                          <div className="h3 text-decoration-none">
                            {postPub.title}
                          </div>
                          {postPub.url && (
                            <a
                              href={postPub.url}
                              target="blank"
                              className="text-success"
                            >
                              {postPub.url}
                            </a>
                          )}
                          {postPub.description && (
                            <p className="text-decoration-none">
                              {postPub.description}...
                            </p>
                          )}
                        </span>
                      </div>
                    </CardBody>
                    <CardFooter className="bt-0 pr-1 pl-1">
                      <PostActions postPub={postPub} />
                    </CardFooter>
                  </Card>
                ))}
              </>
            ) : (
              <div>pas de punlications pour le moment</div>
              // <Loader type="bars" />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
