import React from "react";
import Button from "react-bootstrap/Button";

const Cancel = (props) => {
  return (
    <Button
      style={{ fontSize: "var(--theme-font-size)" }}
      key={"form" + props.field}
      variant="light"
      className="float-right mr-2"
      right="true"
      onClick={() => props.handleCancel()}
      disabled={props.isReadOnly}
    >
      {props.title}
    </Button>
  );
};

export default Cancel;
