import Buttons from "components/Ui/Button";
import { Container, Row, Col } from "reactstrap";
import { userService } from "utils/_services/user.service";

const UserHeader = ({ currentUser }) => {
  return (
    <>
      <div
        className="header d-flex align-items-center"
        style={{
          // background: "rgb(179, 216, 232)",
          background:
            "linear-gradient(90deg,  rgba(179, 216, 232, 1) 0%,  rgba(39, 166, 221, 1) 100%)",
        }}
      >
        {/* Mask */}
        {/* <span className="mask bg-gradient-default opacity-8" /> */}
        {/* Header container */}
        <Container className="d-flex justify-content-center" fluid>
          <Row>
            <Col lg="12" md="12">
              {/* <h1 className="display-2 text-white">
                Bonjour {currentUser.firstName}
              </h1> */}
              <p className="text-white mt-0 mb-2">
                {/* Il s'agit de votre page de profil. Vous pouvez modifier, éditer 
                votre profil pour avoir encore plus de visibilité. */}
                <span className="display-4 text-white">
                  {" "}
                  Bienvenue {currentUser.firstName}
                </span>
                <br />
                Découvrer l'ensemble du potentiel BtpGO , <br />
                Evoluez et faites de votre aventure BtpGO la preuve de votre
                réussite professionnelle
                <span className="ml-5">
                  {" "}
                  <Buttons
                    linkTo={"/ap/" + currentUser.id}
                    buttonText="Voir mon profil publique"
                  />
                </span>
              </p>
              <div className="d-flex flex-row-reverse"></div>
              {/* <Button
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Edit profile
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
