import { Fab, Tooltip } from "@material-ui/core";
import { CameraAlt, FileCopyOutlined } from "@material-ui/icons";

export function ContentCopyIcon(props) {
    return (
        <Tooltip title={"Cliquez pour copier"}>
            <FileCopyOutlined {...props} />
        </Tooltip>
    );
}

export function CameraIcon(props) {
    return (
        <Tooltip title={"Upload photo"}>
            <Fab size="small" color="extended" aria-label="upload photo" {...props}>
                <CameraAlt />
            </Fab>
        </Tooltip>
    );
}