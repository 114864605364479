import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./offre.css";
import Slider from "./Slider";
import Partenaires from "components/Ui/Partenaires";
import "../../../Ui/fond.css";
import SliderPhoto from "components/Photo/Slider/SliderPhoto";

const Offre = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  return (
    <div className="container bgDefault" id="offre">
      <div
        style={{
          alignItems: "center",
          height: "auto",
          maxWidth: "1275px",
        }}
      >
        <div className="col-md-12 mt-5">
          <h2
            className="display-4 pb-4 d-flex justify-content-center"
            style={{ fontWeight: "bold", gap: "1%", color: "rgb(50,104,136)" }}
          >
            Quelques secteurs d’activité représentés
          </h2>
        </div>
        <SliderPhoto />
        <Partenaires />
      </div>
    </div>
  );
};

export default Offre;
