import React from "react";
import "./model.css";

const Modal = ({ open, onClose, sliderData, setSliderData, carousel }) => {
  const handleClick = (index) => {
    const slider = carousel[index];
    setSliderData(slider);
  };
  if (!open) return null;
  return (
    <div>
      <div className="modalContainer">
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <img
            slide={sliderData.image}
            src={sliderData.image}
            style={{ height: "500px", width: "800px", objectFit: "contain" }}
            alt=""
          />
        </div>

        <div className="d-flex justify-content-end mt-6">
          {carousel.map((img, i) => {
            return (
              <div>
                <img
                  className={sliderData.id === i ? "clicked" : ""}
                  key={i}
                  style={{
                    width: "150px",
                    height: "100px",
                    cursor: "pointer",
                  }}
                  alt=""
                  src={img?.image}
                  onClick={() => handleClick(i)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Modal;
