const FinalStep = () => {
  const mainTitle = "Félicitation !! votre site est créé !!";

  return (
    <>
      <div class="p-2 align-self-center text-center">
        <h1 className="text-6xl leading-normal align-middle">{mainTitle}</h1>
        <span class="pull-right label label-default" id="count_message"></span>
      </div>
    </>
  );
};

export default FinalStep;
