import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customerWebsiteService } from "utils/_services/customerWebsite.service";
import { NotificationManager } from "react-notifications";

const initialState = {
  customerWebsite: {},
  status: "idle" | "loading" | "succeeded" | "failed",
  error: null,
};

export const fetchWebsiteData = createAsyncThunk(
  "customerWebsite/getWebsiteData",
  async () => {
    const response = await customerWebsiteService.getCustomerWebsite();
    return response.data;
  }
);

export const manageCustomerWebsite = createAsyncThunk(
  "customerWebsite/manageCustomerWebsite",
  async (websiteData) => {
    const response = await customerWebsiteService.manageCustomerWebsite(
      websiteData
    );
    return response.data;
  }
);

export const customerWebsiteSlice = createSlice({
  name: "customerWebsite",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWebsiteData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchWebsiteData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchWebsiteData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        NotificationManager.error(
          "Un problème est survenu lors de la récupération des données."
        );
      })
      .addCase(manageCustomerWebsite.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(manageCustomerWebsite.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.value === true) {
          state.status = "succeeded";
          NotificationManager.success("Votre site à été créé");
        } else {
          state.status = "failed";
          NotificationManager.error(action.payload.message);
        }
        state.customerWebsite = action.payload;
      });
  },
});

export const getWebSiteData = (state) => state.customerWebsite.customerWebsite;
export const getWebSiteDataStatus = (state) => state.customerWebsite.status;
export const getWebSiteDataError = (state) => state.customerWebsite.error;

// export const { updateName } = userSlice.actions;

export default customerWebsiteSlice.reducer;
