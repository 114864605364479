import React from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from "components/Headers/HeaderGmao";
// import { GiAbstract002 } from "react-icons/gi"

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Link } from "react-router-dom";
const Travaux = () => {
  return (
    <>
      <Header />
      <Container className="mt-5 mb-9" fluid>
        {/* <div className="mb-5"> */}
        <div className="d-flex justify-content-between">
          <div
            className="d-flex"
            style={{ margin: "2rem" }}
          >
            <div
              className="icon icon-shape  text-white rounded-circle shadow"
              style={{ background: "#9077ed" }}
            >
              <i className="ni ni-settings" />
            </div>
            <h1 style={{ marginLeft: "1rem" }}>Demande de travaux</h1>
          </div>
          <div style={{ margin: "2rem" }}>
            <Link to="/admin/search">Trouver le professionel ideal</Link>
          </div>
        </div>

        {/* </div> */}
        <Row>
          <Col className=" mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardBody className=" pt-md-4" style={{ textAlign: "justify" }}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Date de la demande
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue="salut"
                    name="lastName"
                    id="lastName"
                    // placeholder="Nom"
                    type="date"
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Type de demande
                  </label>
                  <select
                    style={{
                      display: "block",
                      width: "100%",
                      height: "calc(1.5em + 1.25rem + 2px)",
                      padding: "0.625rem 0.75rem",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      color: "#8898aa",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      borderRadius: "0.375rem",
                      cursor: "pointer",
                    }}
                    className="form-control-alternative"
                    defaultValue="Sélectionner une option.."
                    name="address"
                    placeholder="Sélectionner une options"
                    id="input-address"
                    type="text"
                  >
                    <option value="De 0 à 5Okm">De 0 à 50km</option>
                    <option value="De 50 à 10Okm">De 50km à 100km</option>
                    <option value="De 100 à plus">De 100km à plus</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Date prévisionnelle
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue="salut"
                    name="lastName"
                    id="lastName"
                    // placeholder="Nom"
                    type="date"
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-white">
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    {/* <Row>
                    <Col md="6" lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="file">
                          Choisir une image de profil
                        </label>
                        <input
                          id="file"
                          type="file"
                          name="picture"
                          onChange={previewPicture}
                          accept=".jpg, .png, .jpeg, .gif"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="file">
                          Logo entreprise
                        </label>
                        <input
                          id="file"
                          type="file"
                          name="picture"
                          accept=".jpg, .png, .jpeg, .gif"
                        />
                      </FormGroup>
                    </Col>
                    </Row> */}

                    <Row>
                      <Col md="9" lg="9">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Site concerné
                          </label>
                          <select
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              borderRadius: "0.375rem",
                              cursor: "pointer",
                            }}
                            className="form-control-alternative"
                            defaultValue="Sélectionner une option.."
                            name="address"
                            placeholder="Sélectionner une options"
                            id="input-address"
                            type="text"
                          >
                            <option value="De 0 à 5Okm">De 0 à 50km</option>
                            <option value="De 50 à 10Okm">
                              De 50km à 100km
                            </option>
                            <option value="De 100 à plus">
                              De 100km à plus
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3" lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Date début réel
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="salut"
                            name="lastName"
                            id="lastName"
                            // placeholder="Nom"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="9" lg="9">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Domaine
                          </label>
                          <select
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              borderRadius: "0.375rem",
                              cursor: "pointer",
                            }}
                            className="form-control-alternative"
                            defaultValue="Sélectionner une option.."
                            name="address"
                            placeholder="Sélectionner une options"
                            id="input-address"
                            type="text"
                          >
                            <option value="De 0 à 5Okm">De 0 à 50km</option>
                            <option value="De 50 à 10Okm">
                              De 50km à 100km
                            </option>
                            <option value="De 100 à plus">
                              De 100km à plus
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3" lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Date de fin
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="salut"
                            name="lastName"
                            id="lastName"
                            // placeholder="Nom"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="9" lg="9">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Type d'équipement
                          </label>
                          <select
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              borderRadius: "0.375rem",
                              cursor: "pointer",
                            }}
                            className="form-control-alternative"
                            defaultValue="Sélectionner une option.."
                            name="address"
                            placeholder="Sélectionner une options"
                            id="input-address"
                            type="text"
                          >
                            <option value="De 0 à 5Okm">De 0 à 50km</option>
                            <option value="De 50 à 10Okm">
                              De 50km à 100km
                            </option>
                            <option value="De 100 à plus">
                              De 100km à plus
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3" lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Date de cloture
                          </label>
                          <Input
                            className="form-control-alternative"
                            name="lastName"
                            id="lastName"
                            placeholder="Nom"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="9" lg="9">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Urgence
                          </label>
                          <select
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              color: "#8898aa",
                              backgroundColor: "#fff",
                              backgroundClip: "padding-box",
                              borderRadius: "0.375rem",
                              cursor: "pointer",
                            }}
                            className="form-control-alternative"
                            defaultValue="Sélectionner une option.."
                            name="address"
                            placeholder="Sélectionner une options"
                            id="input-address"
                            type="text"
                          >
                            <option value="De 0 à 5Okm">De 0 à 50km</option>
                            <option value="De 50 à 10Okm">
                              De 50km à 100km
                            </option>
                            <option value="De 100 à plus">
                              De 100km à plus
                            </option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {/* <hr className="my-4" /> */}
                  {/* Address */}
                  {/* <h6 className="heading-small text-muted mb-4">
                    Informations professionnelles
                  </h6>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Enregistrer
                    </Button>
                  </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <NotificationContainer />
        </Row>
      </Container>
    </>
  );
};

export default Travaux;
