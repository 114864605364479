import React from "react";
import Intro from "components/Welcome/Principal/intro/Intro";
import Services from "components/Welcome/Principal/services/Services";
import Footer from "components/Footers/Footer";
import Offre from "components/Welcome/Principal/offre/Offre";
import Contact from "components/Welcome/Principal/contact/Contact";
import Problematique from "components/Welcome/Principal/problematique/Problematique";

const Welcome = () => {
  return (
    <>
      <Intro />
      <Problematique />
      <Offre />
      <Contact />
      <Services />
      <Footer />
    </>
  );
};

export default Welcome;
