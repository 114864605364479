import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  name: {
    marginTop: 10,
    fontWeight: 400,
  },
  text: {
    fontSize: 10,
    marginBottom: 2,
  },
});

const InvoiceRecipient = ({ item }) => {
  return (
    <View>
      <Text style={styles.text}>{"à l'attention de"}</Text>
      <Text style={{ ...styles.name, ...styles.text }}>{item.name}</Text>
      <Text style={styles.text}>{item.address}</Text>
    </View>
  );
}

export default InvoiceRecipient;
