import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { socialService } from "utils/_services/social.services";

const DeletePostButton = ({ className, postId, deletePostFromArray }) => {
  const [showModal, setShowModal] = useState(false);

  const deletePost = () => {
    socialService
      .deletePost(postId)
      .then((response) => {
        if (response !== undefined) {
          deletePostFromArray(postId);
        } else {
          console.log(response);
        }
        setShowModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={className}>
      <FontAwesomeIcon
        icon={faTrash}
        color="red"
        onClick={() => {
          setShowModal(true);
        }}
      />

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader>
          <h2>Supprimer un post</h2>
        </ModalHeader>
        <ModalBody>
          <p>êtes-vous sûr de vouloir supprimer ce post ?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => deletePost(false)}>
            Supprimer
          </Button>{" "}
          <Button color="danger" onClick={() => setShowModal(false)}>
            Annuler
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeletePostButton;
