import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Buttons from "components/Ui/Button";
import "../../../Ui/fond.css";

const ContactArtisans = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  return (
    <div className="container-fluid bgDefault" id="contact">
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div className="row" style={{ marginRight: "0" }}>
          <div className="col-md-6 ">
            <img
              className="imgContactProperty"
              alt="..."
              src={require("../../../../assets/img/principal/contactArtisan.png")}
            />
          </div>
          <div className="contact" data-aos="fade-right">
            <div className="contact-title" style={{ color: "rgb(50,104,136)" }}>
              Profitez de la méthode BtpGO dès maintenant
            </div>
            <p className="contact-text">
              Vous pouvez vous inscrire en quelques clics et rejoindre la
              communauté qui fera évoluer le secteur du bâtiment.
            </p>
            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactArtisans;
