export const formType = {
  text: "text",
  inputGroup: "inputGroup",
  listGroup: "listGroup",
  filter: "filter",
  typeahead: "typeahead",
  label: "label",
  choice: "choice",
  multiChoice: "multiChoice",
  multiSelectText: "multiSelectText",
  choiceAddons: "choiceAddons",
  component: "component",
  numeric: "numeric",
  description: "description",
  email: "email",
  phone: "phone",
  date: "date",
  time: "time",
  datetime: "datetime",
  checkbox: "checkbox",
  switch: "switch",
  password: "password",
  upload: "upload",
  submit: "submit",
  cancel: "cancel",
  image: "image",
  save: "save",
  recurring: "recurring",
  boolean: "boolean",
  iban: "iban"
};


