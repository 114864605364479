import React from "react";
import { Image, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    marginBottom: 20,
  },
  text: {
    fontSize: 10,
    marginBottom: 2,
    fontStyle: "italic",
    maxWidth: "35%",
  },
  email: {
    color: "#007bff",
    textDecoration: "underline",
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: 100,
    alignSelf: "flex-end",
    alignItems: "flex-end"
  },
});

const InvoiceTitle = ({ item }) => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <View >
        <Text style={styles.title}>{item.commercialName}</Text>
        <Text style={styles.text}>{item.address}</Text>
        <Text style={{ ...styles.email, ...styles.text }}>{item.mail}</Text>
        <Text style={styles.text}>{item.phone}</Text>
        <Text style={styles.text}>{`SIRET: ${item.siret}`}</Text>
        {item.includeTva && (
          <Text style={styles.text}>{`TVA: ${item.tva}`}</Text>
        )}
      </View>
      <View style={{
        marginLeft: "auto"
      }}>
        {item.includeLogo && item.logo && (
          <Image style={styles.image} src={item.logo} />
        )}
      </View>
    </View>
  );
}

export default InvoiceTitle;
