import React, { useState } from 'react';
import './addblog.css'

import {
  Container,
  Row,
} from "reactstrap";
// \

import {cards } from "./DataBlog"

const AddBlog = () => {
  const [toggle, setToggle] = useState(false);

 const  toggleFonction = () => {
    setToggle(!toggle)
  }

    return (
      <div className="background">
        <Container className='containere'>                
          <Row>
              <div className="ombre">
                <div className="row" style={{minHeight: "100vh"}}>
                  
                  <div className="p-3">
               
                  {
                    <div className="carousel-ul" >
                    {cards.map((card, index) => {
                      return (
                        <div key={index} className="carousel-li">
                         <div className="carde">
                          <div className="card-body">
                           <h2 className="card-titre" onClick={toggleFonction}>{card.name}</h2>
                           <p className='article' onClick={toggleFonction}>Lire l'article</p>
                           { toggle ?( 
                           <p className="card-text">{card.text} </p> ): null }
                           <img
                             style={{ maxWidth: "90%" , maxHeight: "40rem", display:"flex", justifyContent:"center"}}
                             alt="..."
                             src={card.imageUrl}
                            />
                          </div>
                         </div>
                       </div>
                      );
                    })}
                  </div>
                 }
                  </div>
                </div>
              </div>
          </Row>
        </Container>
      </div>
    );
};

export default AddBlog;