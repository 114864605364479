export const cell = {
  borderColor: "black",
  height: "24",
  textAlign: "center",
  fontSize: 12,
  borderWidth: 1,
  paddingTop: 5,
};
export const defaultBackgroundColor = {
  headers: "#bff0fd",
  rows: "white",
};

export const text = {
  fontSize: 10,
  paddingLeft: 1,
  paddingRight: 1,
};

export const headerStyle = {
  paddingTop: cell.paddingTop,
  textAlign: cell.textAlign,
  fontSize: cell.fontSize,
  height: cell.height,
};

export const rowStyle = {
  paddingTop: cell.paddingTop,
  textAlign: cell.textAlign,
  fontSize: text.fontSize,
  height: cell.height,
  // paddingLeft: text.paddingLeft,
  // paddingRight: text.paddingRight,
};
