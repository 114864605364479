import React from "react";
import { Card, CardBody, FormGroup, Col, Row, Input, Label } from "reactstrap";
import { AiOutlineStar, AiFillWarning } from "react-icons/ai";
import { FaHammer } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";

const PatrimoineSideBar = () => {
  return (
    <>
      <Col className=" mb-5 mb-xl-0" xl="6">
        <div
          className="d-flex justify-content-center "
          style={{ cursor: "pointer" }}
        >
          <IoIosAddCircle className="icon-p" />
          <p>Modifier graphique</p>
        </div>
        <Row>
          <Col xl="6">
            <label>NC urgent</label>
            <div className="progress" style={{ height: "25px", width: "70%" }}>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <label>Action préventive</label>
            <div className="progress" style={{ height: "25px", width: "70%" }}>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <label>Action currative</label>
            <div className="progress" style={{ height: "25px", width: "70%" }}>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </Col>
          <Col className="" lg="6">
            <div className="d-flex mb-2">To do list en cours</div>
            <div className="d-flex flex-column">
              <FormGroup className="mb-1" check>
                <Label check>
                  <Input type="checkbox" /> Check me out
                </Label>
              </FormGroup>
              <FormGroup className="mb-1" check>
                <Label check>
                  <Input type="checkbox" /> Check me out
                </Label>
              </FormGroup>{" "}
              <FormGroup className="mb-1" check>
                <Label check>
                  <Input type="checkbox" /> Check me out
                </Label>
              </FormGroup>{" "}
              <FormGroup className="mb-1" check>
                <Label check>
                  <Input type="checkbox" /> Check me out
                </Label>
              </FormGroup>{" "}
              <FormGroup className="mb-1" check>
                <Label check>
                  <Input type="checkbox" /> Check me out
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default PatrimoineSideBar;
