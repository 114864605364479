import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import search from "../../../../assets/img/welcome/search.png";
import profil from "../../../../assets/img/welcome/profil.png";
import tools from "../../../../assets/img/welcome/tools.png";
import "./service.css";
import Cards from "components/Ui/Cards";
import Buttons from "components/Ui/Button";
import "../../../Ui/fond.css";

const Services = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  const data = [
    {
      image: search,
      title: "A-search",
      text: `BtpGo vous aide à être mis en relation avec des professionnels qui ont été évalués et notés par d'autres utilisateurs.`,
      lien: "En savoir plus ...",
    },
    {
      image: profil,
      title: "A-profil",
      text: `BtpGO est également un réseau social qui vous permettra d'obtenir plus de visibilité ou de suivre vos artisans préférés et de voir ce quils font.`,
      lien: "En savoir plus ...",
    },
    {
      image: tools,
      title: "A-tools",
      text: ` Notre outil de G.M.A.O. permet à nos clients de gérer leur établissement de manière sereine et optimisée.`,
      lien: "En savoir plus ...",
    },
  ];
  return (
    <div className="container-fluid bgDefault" id="services">
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div
          className="row mt-9 "
          data-aos="fade-right"
          style={{ textAlign: "justify", marginRight: "0" }}
        >
          <div className="col-md-6 ">
            <img
              // className="imgComputer"
              style={{ width: "100%" }}
              alt="..."
              src={require("../../../../assets/img/welcome/inovation.png")}
            />
          </div>

          <div className="col-md-6" style={{ padding: "66px 60px" }}>
            <h2
              className="display-4 pb-4 d-flex mt-3 "
              style={{ gap: "2%", color: "rgb(50,104,136)" }}
            >
              A propos de BtpGo
            </h2>
            <p style={{ color: "black" }}>
              BtpGO est la plateforme du secteur BTP qui peut aider jusqu'à 2
              millions de professionnels français à faire de chaque prestation
              la meilleure expérience possible. Les acteurs du monde entier
              pourront utiliser BtpGO pour consulter les avis et opinions de
              professionnels du BTP.
            </p>
            <p style={{ color: "black" }}>
              Qu'il s'agisse de l'organisation du chantier ou pendant les
              différentes phase de travaux. les professionnels consultent sur
              BtpGO pour comparer les tarifs avantageux des prestataires,
              valeurs ajoutées, certifications, réserver un échange en
              visioconférence pour convenir des modalités d'opérations dans les
              meilleures conditions le plus rapidement possible.
            </p>
            <p style={{ color: "black" }}>
              BtpGO, le meilleur compagnon de gestion de chantier est disponible
              aujourd'hui partout en France.
            </p>
            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="col-md-12 d-flex justify-content-center">
          <h2
            className="display-4 pb-4 d-flex mt-3"
            style={{ gap: "2%", color: "rgb(50,104,136)" }}
          >
            Ce que nous apportons
          </h2>
        </div>
        <Cards data={data} />
      </div>
    </div>
  );
};

export default Services;
