import React, { useEffect, useRef, useState } from "react";
import i18next from "i18next";
import BillsView from "components/Tables/MaterialTable";
import {
  reference,
  priceHt,
  totalPrice,
  creationDate,
} from "./Model";
import NavLink from "components/Forms/others/Navlink";
import { Dialog, dialogType } from "components/Dialogs/Dialog";
import { getTime } from "utils/date";
import { DownloadIcon } from "components/Icons/icons";
import { numberWithCommas } from "utils/maths";
import { paid } from "utils/constants";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { DialogActions, DialogContent } from "@material-ui/core";
import { formType } from "components/Forms/forms";

const newBill = "Nouvelle facture";
const addNewBill = "+ Nouvelle facture";
const now = new Date();

const Bills = (props) => {
  const isLoading = false;
  const [showDialog, setShowDialog] = useState(false);
  const toggle = () => setShowDialog(!showDialog);
  const inputReferenceRef = useRef(null);

  const onInit = () => {
    // Access the input value correctly
    const referenceValue = inputReferenceRef.current.value;
    props.handleInit(-1, { reference: referenceValue });
    toggle();
  };

  useEffect(() => {
    if (showDialog === true && inputReferenceRef.current) {
      // Clear the input and set focus when the dialog opens
      inputReferenceRef.current.value = "";
      inputReferenceRef.current.focus();
    }
  }, [showDialog]);

  return (
    <>
      <BillsView
        id={"Bills"}
        rowId={"id"}
        isLoading={isLoading}
        title={
          <NavLink title={addNewBill} action={toggle} to={"#"} />
        }
        data={props.data}
        columns={[
          reference,   
          {
            title: "Client",
            field: "clientName",
            formType: formType.text,           
          },       
          creationDate,
          {
            ...priceHt,
            ...{
              render: (rowData) => `${numberWithCommas(rowData.totalPriceHt)} ${rowData.currency}`,
            },
          },
          {
            title: i18next.t("VAT"),
            field: "vat",
            render: (rowData) =>
              rowData.includeTva ? `${rowData.tvaAmount} ${rowData.currency}` : "N/A",
          },
          {
            ...totalPrice,
            ...{
              render: (rowData) => `${numberWithCommas(rowData.totalPrice)} ${rowData.currency}`,
            },
          },
          {
            title: "",
            field: "actions",
            width: "5%",
            render: (rowData) => (
              <DownloadIcon
                onClick={(event) => {
                  props.handleDownload(rowData);
                  event.stopPropagation();
                }}
                title={i18next.t("Download")}
              />
            ),
            filtering: false,
          },
        ]}
        rowsPerPage={10}
        handleRowClick={props.handleRowClick}
        handleDelete={props.handleDeleteClick}
        handleFiltering={true}
        rowStyle={(rowData) => ({
          backgroundColor:
            rowData.state === paid
              ? "rgb(206 238 200 / 46%)"
              : rowData.paimentDate < getTime(now)
              ? "rgb(255 0 0 / 15%)"
              : "#FFF",
        })}
      />
      {showDialog && (
        <Dialog
          type={dialogType.medium}
          handleClose={toggle}
          title={newBill}
          handleCancel={toggle}
          component={
            <>
              <DialogContent>
                <FormGroup>
                  <Label for="reference">Réference (numéro de facture)</Label>
                  <Input
                    type="text"
                    name="reference"
                    id="reference"
                    innerRef={inputReferenceRef}
                    placeholder="Enter reference"
                  />
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={toggle} color="secondary">
                  Annuler
                </Button>
                <Button onClick={onInit} type="submit" color="primary">
                  Sauvegarder
                </Button>
              </DialogActions>
            </>
          }
        />
      )}
    </>
  );
};

export default Bills;
