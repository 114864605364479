import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import {
  RiBuilding4Fill,
  RiGroupFill,
  RiMoneyEuroCircleLine,
  RiSmartphoneFill,
  RiRegisteredFill,
} from "react-icons/ri";
import "./profil.css";
import MyCarousel from "components/Ui/MyCarousel";
import defaultProfilePicture from "assets/img/brand/ImageNotFound.png";
import facebookIcon from "assets/img/icons/facebook.png";
import websiteIcon from "assets/img/icons/web.png";
import linkedinIcon from "assets/img/icons/linkedin.png";
import instagramIcon from "assets/img/icons/instagram.png";
import { capitalizeFirstLowercaseRest } from "utils/_helpers/stringUtils";
import { reviewsService } from "utils/_services/review.service";
import ReviewList from "components/Review/ReviewList";

const groupObjects = (data) => {
  if (data) {
    const groupedItems = {};
    data.forEach((item) => {
      let { familyTitle = "Divers" } = item;
      if (familyTitle === null) {
        familyTitle = "Divers";
      }
      if (!groupedItems[familyTitle]) {
        groupedItems[familyTitle] = [];
      }
      groupedItems[familyTitle].push(item);
    });
    return groupedItems;
  } else {
    return {};
  }
};

const renderItem = (item, index) => {
  return (
    <div
      key={index}
      className="card col-xs-12 img-fluid"
      style={{
        width: "20rem",
        boxShadow: "0 4px 18px 0 rgb(219 219 219 / 50%)",
        borderRadius: "1.875rem",
        marginLeft: "1rem",
        height: "13rem",
        overflow: "hidden", // Ajout de cette propriété pour cacher le contenu débordant
      }}
    >
      <img
        src={item.url}
        alt="avatar"
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
      />
    </div>
  );
};

const ViewProfile = ({ searchedUser }) => {

  const [groupedData, setGroupedData] = useState();
  const [reviews, setReviews] = useState([]);
  const [reviewsAverage, setReviewsAverage] = useState(0);
  const ratingsRef = useRef();
  const projectsRef = useRef();

  useEffect(() => {
    setGroupedData(groupObjects(searchedUser.skills));
    reviewsService
      .getReviewssByUserId(searchedUser.company?.id)
      .then((response) => {
        const companyReviews = response.data;
        setReviews(companyReviews);
        if (companyReviews.length > 0) {
          const avg = companyReviews.reduce((a, b) => a + b.rating, 0) / companyReviews.length;
          const avgRounded = avg.toFixed(1);
          setReviewsAverage(avgRounded)
        }
      })
      .catch((err) => console.log(err));
  }, [searchedUser]);

  const scrollToRatings = () => {
    if (ratingsRef.current) {
      ratingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToProjects = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="view-public-profile container">
      <div className="bg-profile"></div>
      <Card className="bg-secondary shadow position-profile p-1">
        <CardBody className="p-1">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <div className="p-2 d-flex flex-row">
              {" "}
              <img
                src={searchedUser.photo?.url ?? defaultProfilePicture}
                alt="avatar"
                id="profilePicture"
                className="rounded img-fluid w-full h-full"
                style={{
                  display: "block",
                  maxWidth: "70px",
                }}
              />
              <div className="m-2">
                <h5 className="title-profile">
                  <i className="fa fa-user mr-2" aria-hidden="true"></i>{" "}
                  {searchedUser.firstName} {searchedUser.lastName}
                </h5>
                <span>{searchedUser.job?.title}</span>
              </div>
            </div>

            <div className="p-2">
              <h3>
                <RiSmartphoneFill
                  className="mr-2 mb-1"
                  // fontSize="1rem"
                  color="rgb(111,174,222)"
                />
                <span className="company-titles">{searchedUser.phoneNumber}</span>
              </h3>
            </div>
            <div className="p-2">
              <h3>
                <i
                  className="fa fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>{" "}
                {searchedUser.commune?.name}
              </h3>
            </div>
          </div>
        </CardBody>
      </Card>
      <div style={{ top: "-70px", position: "relative" }}>


        {(reviewsAverage > 0 || (searchedUser.userWorkPhotos && searchedUser.userWorkPhotos.length > 0)) &&
          <Row >
            <Col md="12" lg="12">
              <Card className="bg-secondary shadow mb-2">
                <CardBody className="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                  {reviewsAverage > 0 &&
                    <div className="cursor-pointer" onClick={scrollToRatings}>
                      <u>
                        <span className="review-stars">{'★'}</span>
                        <span><b>{reviewsAverage} - {reviews.length} Avi(s)</b></span>
                      </u>
                    </div>}

                  {(searchedUser.userWorkPhotos && searchedUser.userWorkPhotos.length > 0) &&
                    <div className="cursor-pointer ml-4" onClick={scrollToProjects}>
                      <u>
                        <b>Voir mes projets</b>
                      </u>
                    </div>}

                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        <Row >
          <Col md="12" lg="12" >
            <Card className="bg-secondary shadow mb-2">
              <CardHeader>
                <h2>Présentation</h2>
              </CardHeader>
              <CardBody>{searchedUser.howIAmDescription}</CardBody>
            </Card>

            {/* <Card className="bg-secondary shadow mb-2">
            <CardHeader>
              <h2>Ce que j'aime</h2>
            </CardHeader>
            <CardBody>{searchedUser.hobbies}</CardBody>
          </Card> */}
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="12" >
            <Card className="bg-secondary shadow mb-2">
              <CardHeader>
                <h2>Mon entreprise</h2>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="6" lg="6">
                    <span>Entreprise - </span>
                    <RiBuilding4Fill
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {" "}
                      {searchedUser.company?.name}
                    </span>
                  </Col>
                  <Col md="6" lg="6">
                    <span>Siret - </span>
                    <RiRegisteredFill
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {searchedUser.company?.siretNumber}
                    </span>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="6" lg="6">
                    <span>Chiffre d'affaire - </span>
                    <RiMoneyEuroCircleLine
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {searchedUser.company?.turnover ?? "-"}
                    </span>
                  </Col>
                  <Col md="6" lg="6">
                    <span>Nombre d'employés - </span>
                    <RiGroupFill
                      className="mr-2 mb-1"
                      // fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                    <span className="company-titles">
                      {searchedUser.company?.employeesNumber ?? "-"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  {groupedData &&
                    Object.entries(groupedData).map(
                      ([familyTitle, items]) => {
                        return (
                          <Col md="6" lg="6">
                            <h3 >{familyTitle}</h3>
                            <ul>
                              {items.map((item, index) => (
                                <li key={index}>{item.description}</li>
                              ))}
                            </ul>
                          </Col>
                        );
                      }
                    )}

                  {(searchedUser.company?.rcpInsurance === true || searchedUser.company?.insurances) && (
                    <Col md="6" lg="6">
                      <h3>Assurances</h3>
                      <ul>
                        {searchedUser.company.rcpInsurance === true &&
                          <li key={"rcpInsurance"}>
                            Assurance RCP et décennale
                          </li>}
                        {searchedUser.company?.insurances
                          && searchedUser.company.insurances.map((value, index) => <li key={`insurance-${index}`}>{value.title}</li>)}
                      </ul>
                    </Col>
                  )}
                </Row>

                <div>
                  {(searchedUser.company?.facebookLink ||
                    searchedUser.company?.instagramLink ||
                    searchedUser.company?.websiteLink ||
                    searchedUser.company?.linkedinLink) && (
                      <>
                        <h2>Nos liens</h2>

                        {searchedUser.company?.websiteLink && (
                          <a
                            href={searchedUser.company?.websiteLink}
                            title="Notre Site"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={websiteIcon}
                              alt="SiteWeb"
                              className="m-2"
                              width="50px"
                              height="50px"
                            ></img>
                          </a>
                        )}
                        {searchedUser.company?.facebookLink && (
                          <a
                            href={searchedUser.company?.facebookLink}
                            title="Notre Facebook"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={facebookIcon}
                              alt="Facebook"
                              className="m-2"
                              width="50px"
                              height="50px"
                            ></img>
                          </a>
                        )}
                        {searchedUser.company?.linkedinLink && (
                          <a
                            href={searchedUser.company?.linkedinLink}
                            title="Notre Linkedin"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={linkedinIcon}
                              alt="Linkedin"
                              className="m-2"
                              width="50px"
                              height="50px"
                            ></img>
                          </a>
                        )}
                        {searchedUser.company?.instagramLink && (
                          <a
                            href={searchedUser.company?.instagramLink}
                            title="Notre Instagram"
                            className="cursor-pointer"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={instagramIcon}
                              alt="Instagram"
                              className="m-2"
                              width="50px"
                              height="50px"
                            ></img>
                          </a>
                        )}
                      </>
                    )}
                </div>
              </CardBody>
            </Card>
            {searchedUser.workAvailabilities &&
              searchedUser.workAvailabilities.length > 1 && (
                <Card className="bg-secondary shadow mb-2">
                  <CardHeader>
                    <h2>Nos disponibilités</h2>
                  </CardHeader>
                  <CardBody>
                    Nous sommes disponibles du{" "}
                    {searchedUser.workAvailabilities.map((x) => {
                      switch (x.code) {
                        case "FirstDayPart":
                        case "SecondDayPart":
                        case "ThirdDayPart":
                          return x.title + ", ";
                        case "FiveWorkDays":
                          return " tous les " + x.title;
                        case "WeekendWorkDay":
                          return " et le " + x.title;
                        default:
                          return "";
                      }
                    })}{" "}
                  </CardBody>
                </Card>
              )}
          </Col>
        </Row>

        <Row >
          <Col md="12" lg="12" style={{ paddingLeft: "15px" }}>
            {reviews && reviews.length > 0 &&
              <Card className="bg-secondary shadow mb-2">
                <CardHeader>
                  <h2 ref={ratingsRef} >Avis de l'entreprise {capitalizeFirstLowercaseRest(searchedUser.company?.name)}</h2>
                </CardHeader>
                <CardBody>
                  {reviews && <ReviewList reviews={reviews} />}
                </CardBody>
              </Card>
            }
          </Col>
        </Row>
        {searchedUser.userWorkPhotos && searchedUser.userWorkPhotos.length > 0 && (
          <Row>
            <Col md="12" lg="12">
              <Card className="bg-secondary shadow mb-2">
                <CardHeader>
                  <h2 ref={projectsRef}>Mes projets</h2>
                </CardHeader>
                <CardBody>
                  <MyCarousel
                    infinite={true}
                    autoplay={false}
                    autoplaySpeed={3000}
                    data={searchedUser.userWorkPhotos}
                    renderItem={renderItem}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>)}
      </div>
    </div>
  );
};

export default ViewProfile;
