import React from 'react'

export const ConfirmationMessage = (props) => {
    const { mainMessage, subMessage } = props;

    return (
        <div className="flex items-center ">
            <div className="d-flex justify-content-center text-xl item-center p-9 font-black">
                {" "}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="green"
                    className="bi bi-check"
                    viewBox="0 0 16 16"
                    style={{ "margin-top": "-10px" }}
                >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
                </svg>
                <h1>{mainMessage}</h1>
                {subMessage && <span className="pull-right label label-default" ></span>}
            </div>
        </div>
    )
}
