import Axios from "./caller.service";

let createPayment = () => {
  return Axios.post("/api/Payment");
};

let paymentConfig = () => {
  return Axios.get("/api/Payment/config");
};

let createCheckoutSession = (priceId, userId, email) => {
  return Axios.post("/api/Payment/create-checkout-session", {
    userId,
    email,
    priceId,
  });
};

let getUserSubscription = () => {
  return Axios.get("/api/Payment/get-user-subsciption");
};

let getUserPortalLink = () => {
  return Axios.post("/api/Payment/create-portal-link");
}

let getActivesPrices = () => {
  return Axios.get("/api/Payment/get-actives-prices");
}

export const paymentService = {
  createPayment,
  paymentConfig,
  createCheckoutSession,
  getUserSubscription,
  getUserPortalLink,
  getActivesPrices
};
