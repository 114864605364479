import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const Buttons = ({ linkTo, buttonClass, onClick, buttonText }) => {
  const defaultButtonStyle = {
    color: "white",
    background:
      "linear-gradient(90deg, rgba(111,174,222,1) 0%, rgba(16,122,202,1) 100%)",
    cursor: "pointer !important",
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link to={linkTo}>
      <Button
        className={`my-3 ${buttonClass}`}
        style={defaultButtonStyle}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </Link>
  );
};

export default Buttons;
