import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    marginBottom: 2,
  },
});

const InvoiceDates = ({ item }) => {
  return (
    <View>
      <Text style={styles.text}>{`Réalisé le ${item.executionDate}`}</Text>
      {/* <Text style={styles.text}>{`Période de facturation du ${item.billingDate}`}</Text> */}
      <Text style={styles.text}>{`Date d’échéance du règlement ${item.paimentDate}`}</Text>
    </View>
  );
}
export default InvoiceDates;
