import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Splide, SplideSlide } from '@splidejs/react-splide';
import clim from "assets/img/welcome/clim.jpg";
import electricien from "assets/img/welcome/electricien.jpg";
import garden from "assets/img/welcome/garden.jpg";
import macon from "assets/img/welcome/macon.jpg";
import peintre from "assets/img/welcome/peintre.jpg";
import menuisier from "assets/img/welcome/menuisier.jpg";
import plombier from "assets/img/welcome/plombier.jpg";
import serrurier from "assets/img/welcome/serrurier.jpg";
import charpentier from "assets/img/welcome/charpentier.jpg";
import alarme from "assets/img/welcome/alarme.jpg";
import carreleur from "assets/img/welcome/carreleur.jpg";
import cuisiniste from "assets/img/welcome/cuisiniste.jpg";
import domotique from "assets/img/welcome/domotique.jpg";
import photovoltaique from "assets/img/welcome/photovoltaique.jpg";

import '@splidejs/react-splide/css';

import './styles.css';

// import required modules
const carousel = [
  {
    image: peintre,
    title: "Peintre",
  },
  {
    image: charpentier,
    title: "Charpentier",
  },
  {
    image: plombier,
    title: "Plombier",
  },
  {
    image: menuisier,
    title: "Menuisier",
  },
  {
    image: serrurier,
    title: "Serrurier",
  },
  {
    image: clim,
    title: "Climatisation",
  },
  {
    image: carreleur,
    title: "Carreleur",
  },
  {
    image: electricien,
    title: "Electricien",
  },
  {
    image: domotique,
    title: "Domotique",
  },
  {
    image: garden,
    title: "Jardinier",
  },
  {
    image: macon,
    title: "Maçon",
  },
  {
    image: cuisiniste,
    title: "Cuisiniste",
  },
  {
    image: photovoltaique,
    title: "Photovoltaïque",
  },
  {
    image: alarme,
    title: "Alarme",
  },
];

export default function SliderPhoto() {
  return (
    <>
      <div className="container bgDefault mt-3">
        <div>
          <Splide
            options={{
              perPage: 3,
              height: '25rem',
              rewind: true,
              // gap: '1rem',
              breakpoints: { 1000: { perPage: 2 }, 768: { perPage: 1 } }
            }}
            aria-labelledby="basic-example-heading"
          >
            {carousel.map((element, k) => {
              return (
                <SplideSlide key={k} className="splide-slider-element">

                  <div className="card col-xs-12 slider-job" >
                    <img
                      style={{
                        height: "16em",
                        objectFit: "contain",
                      }}
                      alt="..."
                      src={element.image}
                    />
                    <div className="card-body">
                      <h2 className="card-title d-flex justify-content-center">
                        {element.title}
                      </h2>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </>
  );
}
