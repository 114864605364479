import {
  searchBy,
  filterBymulti,
  sortByPropertyDesc,
  sortByPropertyAsc,
} from "../utils/converter";

export const getMaterialTableParams = (query, data) => {
  var res = data
    ? query.search && query.search !== ""
      ? searchBy(data, query.search)
      : data
    : [];

  var filteredRes = res
    ? query.filters && query.filters.length > 0
      ? filterBymulti(res, query.filters)
      : res
    : [];

  var orderedRes =
    query.orderBy && query.orderDirection
      ? query.orderDirection === "asc"
        ? sortByPropertyAsc(filteredRes, query.orderBy.field)
        : sortByPropertyDesc(filteredRes, query.orderBy.field)
      : filteredRes;

  const page = getPage(orderedRes.length, query.pageSize, query.page);

  return {
    data: orderedRes.slice(
      page * query.pageSize,
      page * query.pageSize + query.pageSize
    ),
    page: page,
    totalCount: orderedRes ? orderedRes.length : 0,
    pageSize: query.pageSize,
  };
};

const getPage = (totalLines, pageSize, currentPage) => {
  if (currentPage === 0) return 0;
  else if (totalLines / pageSize > currentPage) return currentPage;
  else return currentPage - 1;
};
