import { useEffect,  useState } from "react";
import "react-notifications/lib/notifications.css";
import { Progress, Badge } from "reactstrap";
import PropTypes from "prop-types";
import { Tabs, Typography, Tab, Box } from "@material-ui/core";
import { Container } from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import GenericHeader from "components/Headers/GenericHeader";
import PhotoUploader from "components/Photo/PhotoUploader";

import ViewProfile from "components/Profile/ViewProfile";
import EditProfile from "components/Profile/EditProfile";
import EditProfilePro from "components/Profile/EditProfilePro";
import EditCompany from "components/Profile/EditCompany";

import Buttons from "components/Ui/Button";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserStatus,
  getCurrentUser,
  fetchUser,
} from "features/slices/userSlice";
import ViewPublicProfile from "components/Profile/ViewPublicProfile";
import EditCompanyCommunication from "components/Profile/EditCompanyCommunication";
import ReviewsPage from "views/Review/ReviewsPage";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const { userData } = useParams();

  const user = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser());
    }
  }, [userStatus, dispatch]);

  const [selectedTabValue, setSelectedTabValue] = useState(0);

  const handleTabChange = (event, newSetSelectedTabValue) => {
    setSelectedTabValue(newSetSelectedTabValue);
  };

  const isNumeric = (string) => Number.isFinite(+string)

  return (
    <>
      {/* {userStatus === "succeeded" && */}
      {user &&
        (userData && user.id === userData ? (
          <>
            <GenericHeader />
            <div className="d-flex justify-content-center">
              <Buttons
                linkTo={"/ap/user-profile"}
                buttonText="Modifier mon profil"
              />
            </div>

            <Container className="mt-2">
              <ViewProfile searchedUser={user} />
            </Container>
          </>
        ) : (isNumeric(userData) ?
          <>
            <GenericHeader />
            <ViewPublicProfile />
          </>
          : (
            <>
              <UserHeader currentUser={user} />
              <Container>
                <div className="progress-wrapper">
                  <div className="progress-info row">
                    <div className="progress-label mb-2 col-md-5 col-sm-11 text-lg-left text-center">
                      <span className="text-sm">
                        Profil rempli à{" "}
                        <strong>{user.profileFilledPercentage} %</strong>
                      </span>
                    </div>
                    <div className="col-md-5 mb-2 col-sm-11 text-center  text-lg-right">
                      <Badge
                        className="text-sm "
                        color={user.profileStatus === 0 ? "danger" : "success"}
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        Profil{" "}
                        {user.profileStatus === 0
                          ? "en cours de validation"
                          : "validé"}
                      </Badge>
                    </div>
                  </div>
                  <Progress
                    max="100"
                    value={user.profileFilledPercentage}
                    color="success"
                  />
                </div>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="scrollable"
                      className="w-100"
                      value={selectedTabValue}
                      onChange={handleTabChange}
                      aria-label="BtpGo tabs"
                    >
                      <Tab label="Perso" {...a11yProps(0)} />
                      <Tab label="Pro" {...a11yProps(1)} />
                      <Tab label="Entreprise" {...a11yProps(2)} />
                      <Tab label="Communication" {...a11yProps(3)} />
                      <Tab label="Mes projets" {...a11yProps(4)} />
                      <Tab label="Avis" {...a11yProps(5)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={selectedTabValue} index={0}>
                    <EditProfile userData={user} />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTabValue} index={1}>
                    <EditProfilePro userData={user} />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTabValue} index={2}>
                    <EditCompany companyData={user.company} userId={user.id} />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTabValue} index={3}>
                    <EditCompanyCommunication companyData={user.company} userId={user.id} />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTabValue} index={4}>
                    <PhotoUploader />
                  </CustomTabPanel>
                  <CustomTabPanel value={selectedTabValue} index={5}>
                    <ReviewsPage companyId={user.company.id} companyName={user.company.name} />
                  </CustomTabPanel>
                </Box>
              </Container>
            </>
          )))}
    </>
  );
};

export default Profile;
