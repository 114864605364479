import React from "react";
import i18next from "i18next";
import { formType } from "components/Forms/forms";
import { months, years, getDate } from "utils/date";
import { billStateLookup } from "utils/constants";
import { numberWithCommas } from "utils/maths";
import Divider from "components/Forms/others/Divider";
import { toKeyWord } from "components/TextEditor";


export const reference = {
  field: "reference",
  title: "Réference",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
};

export const amount = {
  field: "amount",
  title: "Montant",
  formType: formType.numeric,
  isRequired: true,
  isReadOnly: false,
  placeholder: "Montant",
};

// export let reference = {
//   field: "reference",
//   title: "Réference",
//   formType: formType.text,
//   lookup: {},
//   isRequired: true,
//   isReadOnly: false,
// };

export const month = {
  field: "month",
  title: i18next.t("Month"),
  formType: formType.choice,
  lookup: months(),
  isRequired: true,
  isReadOnly: false,
  placeholder: i18next.t("Month"),
  sortBy: "no sort",
};

export const year = {
  field: "year",
  title: i18next.t("Year"),
  formType: formType.choice,
  lookup: years(new Date().getFullYear() - 2),
  isRequired: true,
  isReadOnly: false,
  placeholder: i18next.t("Year"),
};

export const state = {
  field: "state",
  title: i18next.t("State"),
  formType: formType.choice,
  lookup: billStateLookup(),
  isRequired: true,
  isReadOnly: false,
};

export const currency = {
  field: "currency",
  title: i18next.t("Currency"),
  formType: formType.choice,
  lookup: "$",
  isRequired: true,
  isReadOnly: false,
  placeholder: i18next.t("Currency"),
};

// export const releaseDate = {
//   field: "serviceExecutionDate",
//   title: i18next.t("Billing period"),
//   formType: formType.datetime,
//   isRequired: false,
//   isReadOnly: false,
// };

export const collectDate = {
  field: "collectDate",
  title: "Date de paiement",
  formType: formType.date,
  isRequired: true,
  isReadOnly: false,
  render: (rowData) =>
    rowData.collectDate ? getDate(rowData.collectDate) : "",
  customFilterAndSearch: (term, rowData) =>
    getDate(rowData.collectDate).includes(term),
};

export const creationDate = {
  field: "creationDate",
  title: "Date de création",
  formType: formType.date,
  isRequired: false,
  isReadOnly: false,
  render: (rowData) =>
    rowData.creationDate ? getDate(rowData.creationDate) : "",
};

export const paimentDate = {
  field: "paimentDate",
  title: "Date d’échéance du règlement",
  formType: formType.date,
  isRequired: false,
  isReadOnly: false,
  render: (rowData) =>
    rowData.paimentDate ? getDate(rowData.paimentDate) : "",
  customFilterAndSearch: (term, rowData) =>
    getDate(rowData.paimentDate).includes(term),
};

export const serviceName = {
  field: "serviceName",
  title: "Nom de service",
  formType: formType.text,
  isRequired: false,
  isReadOnly: false,
};

export const quantity = {
  field: "quantity",
  title: "Quantité",
  formType: formType.numeric,
  isRequired: true,
  isReadOnly: false,
};

export const priceHt = {
  field: "totalPriceHt",
  title: "Prix HT",
  formType: formType.numeric,
  isRequired: true,
  isReadOnly: false,
  render: (rowData) =>
    rowData.totalPriceHt ? numberWithCommas(rowData.totalPriceHt) : rowData.totalPriceHt,
};

export const totalPrice = {
  field: "totalPrice",
  title: "Prix TTC",
  formType: formType.numeric,
  isRequired: false,
  isReadOnly: true,
  render: (rowData) =>
    rowData.totalPrice
      ? numberWithCommas(rowData.totalPrice)
      : rowData.totalPrice,
};

export const includeTva = {
  field: "includeTva",
  title: "Inclure TVA",
  formType: formType.switch,
  isRequired: false,
  isReadOnly: false,
  isInline: true,
};

export const includePenalty = {
  field: "includePenalty",
  title: "Inclure penalité",
  formType: formType.switch,
  isRequired: false,
  isReadOnly: false,
  isInline: true,
};

export const includeLogo = {
  field: "includeLogo",
  title: i18next.t("Include Logo"),
  formType: formType.switch,
  isRequired: false,
  isReadOnly: false,
  isInline: true,
};

export const tva = {
  field: "tva",
  title: i18next.t("Tva %"),
  formType: formType.numeric,
  isRequired: true,
  isReadOnly: false,
  label: i18next.t("%")
};

export const penaltyRate = {
  field: "penaltyRate",
  title: i18next.t("Penalty rate %"),
  formType: formType.numeric,
  isRequired: true,
  isReadOnly: false,
};

export const penaltyDate = {
  field: "penaltyDate",
  title: "Date de pénalité",
  formType: formType.date,
  isRequired: false,
  isReadOnly: false,
};

export const submit = {
  title: i18next.t("Save"),
  formType: formType.submit,
};

export const next = {
  title: "Continuer",
  formType: formType.submit,
};

export const cancel = {
  title: "Annuler",
  formType: formType.cancel,
};

export const dividerComponent = (label) => ({
  field: label,
  formType: formType.component,
  content: <Divider label={label} />,
});

export const suggestions = {
  [month.title]: month.field,
  [year.title]: year.field,
  [reference.title]: reference.field,
  [serviceName.title]: serviceName.field,
  [totalPrice.title]: totalPrice.field
}

export const replaceSuggestions = (text, item) => {
  text = text.replaceAll(toKeyWord(month.field), months()[item[month.field]]);
  text = text.replaceAll(toKeyWord(year.field), item[year.field]);
  text = text.replaceAll(toKeyWord(reference.field), item[reference.field]);
  text = text.replaceAll(toKeyWord(serviceName.field), item[serviceName.field]);
  text = text.replaceAll(toKeyWord(totalPrice.field), numberWithCommas(item[totalPrice.field]));
  return text;
};

export const billForm = (
  withTva = false,
  withPenalty = false,
  collected = false,
  isDirty
) => {
  const form = {
    rows: [
      {
        columns: [{ size: 12, render: { ...serviceName } }],
      },
      {
        columns: [
          { size: 4, render: { ...quantity } },
          { size: 4, render: { ...priceHt } },
          { size: 4, render: { ...totalPrice } },
        ],
      },
      {
        columns: [
          { size: 12, render: { ...dividerComponent(i18next.t("Options")) } },
        ],
      },
      {
        columns: [
          { size: 6, render: { ...includeLogo } },
        ],
      },
    ],
  };
  if (isDirty)
    form.footer = [{ render: { ...submit } }, { render: { ...cancel } }]

  if (withTva && withPenalty)
    form.rows.push(
      ...[
        {
          columns: [
            { size: 6, render: { ...includeTva } },
            { size: 6, render: { ...tva } },
          ],
        },
        {
          columns: [
            { size: 6, render: { ...includePenalty } },
            { size: 6, render: { ...penaltyRate } },
          ],
        },

      ]
    );
  else if (!withTva && withPenalty)
    form.rows.push(
      ...[
        {
          columns: [{ size: 6, render: { ...includeTva } }],
        },
        {
          columns: [
            { size: 6, render: { ...includePenalty } },
            { size: 6, render: { ...penaltyRate } },
          ],
        },
      ]
    );
  else if (withTva && !withPenalty)
    form.rows.push(
      ...[
        {
          columns: [
            { size: 6, render: { ...includeTva } },
            { size: 6, render: { ...tva } },
          ],
        },
        {
          columns: [{ size: 6, render: { ...includePenalty } }],
        },
      ]
    );
  else
    form.rows.push(
      ...[
        {
          columns: [{ size: 6, render: { ...includeTva } }],
        },
        {
          columns: [{ size: 6, render: { ...includePenalty } }],
        },
      ]
    );

  form.rows.push(
    ...[
      {
        columns: [{ size: 12, render: { ...dividerComponent("Dates") } }],
      },
      {
        columns: [{ size: 12, render: { ...creationDate } }],
      },
      {
        columns: [{ size: 12, render: { ...paimentDate } }],
      },
    ]
  );

  if (collected)
    form.rows.push(
      ...[
        {
          columns: [{ size: 12, render: { ...collectDate } }],
        },
      ]
    );

  return form;
};

export const stateForm = () => {
  const form = {
    rows: [
      {
        columns: [
          { size: 4, render: { ...reference, ...{ isRequired: false, isReadOnly: true } } },
          { size: 8, render: { ...serviceName, ...{ isRequired: false, isReadOnly: true } } }
        ],
      },
      {
        columns: [
          { size: 4, render: { ...state, ...{ isRequired: false, isReadOnly: true } } },
          { size: 8, render: { ...collectDate } }],
      }
    ],
    footer: [{ render: { ...submit } }, { render: { ...cancel } }],
  };

  return form;
};

export const newBillForm = () => {
  return {
    rows: [
      {
        columns: [{ size: 12, render: { ...reference } }],
      },
      // {
      //   columns: [
      //     { size: 6, render: { ...month } },
      //     { size: 6, render: { ...year } },
      //   ],
      // },
    ],
    footer: [{ render: { ...next } }, { render: { ...cancel } }],
  };
};

export const billDialogForm = () => {
  return {
    rows: [
      {
        columns: [
          { size: 12, render: { ...reference, ...{ isReadOnly: true } } },
        ],
      },
      {
        columns: [
          { size: 12, render: { ...serviceName, ...{ isReadOnly: true } } },
        ],
      },
      {
        columns: [{ size: 12, render: { ...amount } }],
      },
      {
        columns: [{ size: 12, render: { ...collectDate } }],
      },
    ],
    footer: [{ render: { ...submit } }, { render: { ...cancel } }],
  };
};
