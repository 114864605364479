import React, { useState } from "react";
import TextareaWithLimit from "components/Ui/TextareaWithLimit";
import { Button } from "reactstrap";

const AddData = ({ onSubmit, onCancel, name, placeholder, rowsCount = 1, maxlength = 100 }) => {
  const [value, setValue] = useState("");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <div className="center-content text-center">
        <div className="row ">
          <div className="col-12" >
            <TextareaWithLimit
              id={name}
              name={name}
              maxlength={maxlength}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              rowsCount={rowsCount}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right" >
            <Button
              className="my-3 btn btn-primary"
              title="Annuler"
              onClick={onCancel}
            >
              Annuler
            </Button>
            <Button
              className="my-3 btn btn-success"
              title="Ajouter"
              onClick={() => onSubmit(value)}
            >
              Ajouter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddData;
