import React from "react";
import { Document, pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver'
import { imageToBase64 } from "utils/fileloader";

export const Downloader = async ({ title, page }) => {
    const blob = await pdf(
        <Document title={title} author={"Octolance"} producer={"Octolance"} creator={"Octolance"} >
            {page}
        </Document>
    ).toBlob()
    saveAs(blob, title)
};

export const DownloadAsBase64 = async ({ title, page }) => {
    const blob = await pdf(
        <Document title={title} author={"Octolance"} producer={"Octolance"} creator={"Octolance"} >
            {page}
        </Document>
    ).toBlob();

    return imageToBase64(blob);
};

