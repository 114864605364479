import { data } from "jquery";
import Axios from "./caller.service";

let createSite = (data) => {
  return Axios.post(`/api/Sites`, data);
};

let getSite = (id) => {
  return Axios.get(`/api/Sites/${id}`);
};

let updateSite = (id) => {
  return Axios.put(`/api/Sites/${id}`);
};

let deleteSite = (id) => {
    return Axios.delete(`/api/Sites/${id}`);
}

let searchSite = ({companyId}) => {
  return Axios.post(`/api/Sites/search`,{companyId});
}

export const siteService = {
    createSite,
    getSite,
    updateSite,
    deleteSite,
    searchSite
};
